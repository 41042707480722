import { postJSON } from '@/api/core/http/post'

import {
  Web3PurchaseInput,
  Web3PurchaseResponse,
  web3PurchaseResponseSchema
} from './schema'

const path = '/user/web3/purchase/'

export const submitWeb3Purchase = async (
  input: Web3PurchaseInput
): Promise<Web3PurchaseResponse> =>
  postJSON<Web3PurchaseResponse>(path, input, {}, web3PurchaseResponseSchema)
