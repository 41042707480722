import classNames from 'classnames'
import Icon from './Icon'

type Props = {
  icon?: 'solanaWordmark' | 'solanaWordmarkWhite'
  className?: string
  iconClassName?: string
}

const PoweredBySolana = ({
  icon = 'solanaWordmark',
  className,
  iconClassName
}: Props) => {
  return (
    <span className={classNames('body-xs', className)}>
      Powered by <Icon name={icon} className={iconClassName} />
    </span>
  )
}

export default PoweredBySolana
