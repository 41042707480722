import React from 'react'
import classNames from 'classnames'

// Constants & Utils
import IdentityVerificationState from '@/types/identityVerificationState'
import withAuth from '@/hoc/withAuth'

// Components & Helpers
import { DefaultLayout } from '@/layouts'
import Spinner from '@/components/Spinner'
import DropppCredits from '@/components/DropppCredits'
import RoundedBox from '@/components/RoundedBox'
import { AddressPanel } from '@/features/wallet/AddressPanel'

import VerifyIdentityCta from '@/features/wallet/components/VerifyIdentityCta'
import { WalletPanel } from '@/features/wallet/WalletPanel'

// API
import { useAuth } from '@/contexts/auth'
import { useWallet } from '@/features/wallet/providers/wallet'

////////////////////////////////////////////////////////////////////////////////
// Constants

export const CLOUDINARY_WALLET_PATH = 'pages/wallet/'

////////////////////////////////////////////////////////////////////////////////
// Page / Content

const WalletPageContent = () => {
  // Hooks ////////////////////////////////////////////////////////////////////////
  const { user = {} } = useAuth()
  const { isWalletLoading, identityVerification, migrationNeeded, wallet } =
    useWallet()

  // Early Return ////////////////////////////////////////////////////////////////
  if (isWalletLoading || !user) return <Spinner />

  // Setup ///////////////////////////////////////////////////////////////////////
  const { credit = 0 } = user

  const showDropppCredit = credit > 0

  const verifyState = identityVerification || IdentityVerificationState.New
  const identityVerificationIsCompleted =
    verifyState === IdentityVerificationState.Completed

  const dontShowVerifyIdentityCta =
    (identityVerificationIsCompleted && wallet) || migrationNeeded

  // Render //////////////////////////////////////////////////////////////////////

  const meta = {
    title: 'Wallet'
  }

  return (
    <DefaultLayout seo={meta}>
      <div
        className={classNames('container max-md:max-w-[424px] max-w-[1088px]')}
      >
        <h1 className={classNames('h2 mb-4')}>Wallet</h1>

        {!dontShowVerifyIdentityCta && (
          <VerifyIdentityCta identityVerificationState={verifyState} />
        )}

        <div className="flex flex-col md:flex-row gap-4 items-start">
          <div className="flex-[4] flex flex-col gap-3">
            {showDropppCredit && <DropppCredits credit={credit} />}
            <WalletPanel />
          </div>
          <RoundedBox className="flex-[5] flex flex-col gap-3">
            <AddressPanel />
          </RoundedBox>
        </div>
      </div>
    </DefaultLayout>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Layout & Export

const WalletPage = withAuth(WalletPageContent)

export default WalletPage
