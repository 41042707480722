import React from 'react'
import { classNames } from '@/util/tailwindHelpers'
import MultiToggle from '@/components/MultiToggle'
import ProfileMedia from '@/components/Profile/ProfileMedia'
import InfiniteScroll from 'react-infinite-scroller'
import { TOGGLE_ITEMS } from './ProfileEditModal'
import ProfileMediaItem from './ProfileMediaItem'
import ProfileUnSetStickerItem from './ProfileUnSetStickerItem'
import { CONTENT } from './content'
import { PROFILE_MEDIA_TYPES } from './types'
import { useProfileMediaContext } from './Context'
import { InfiniteScrollLoader } from '@/components/InfiniteScrollLoader'
import { MODAL_OVERLAY_ID } from '../BaseModal'

export default function Body() {
  // STATE --------------------------------------------------
  const {
    currentMediaType,
    profileMedia,
    onViewChange,
    onItemSelect,
    selectedItems: { avatar, sticker },
    mediaGetResponse: { avatars: avatarResponse, stickers: stickerResponse }
  } = useProfileMediaContext()

  // Get Media --------------------------------------------------

  const { data, fetchNextPage, hasNextPage } =
    currentMediaType === PROFILE_MEDIA_TYPES.avatar
      ? avatarResponse
      : stickerResponse

  // @ts-expect-error - related to infinite query not passing back type
  const currentItems = data?.items || []

  // Variables --------------------------------------------------

  const isAvatarView = currentMediaType === PROFILE_MEDIA_TYPES.avatar
  const content = CONTENT[currentMediaType]
  const currentSelectedItem = isAvatarView ? avatar.index : sticker.index
  const hasChainAssets = currentItems.some(item => item.chain_asset_id)

  const currentMediaItems = currentItems.map((asset, index: number) => (
    <ProfileMediaItem
      key={`${currentMediaType} - ${index}`}
      asset={asset}
      type={currentMediaType}
      isSelected={currentSelectedItem === index}
      handleSelect={({ id, chain_asset_id }) =>
        onItemSelect({ id, chain_asset_id, index })
      }
    />
  ))

  if (!isAvatarView) {
    // We are relying on the "unset" option to be the first item in the list, and the index to be -1 since it does not exist in the array of sticker options
    currentMediaItems.unshift(
      <ProfileUnSetStickerItem
        key="unset"
        handleSelect={() =>
          onItemSelect({ index: -1, chain_asset_id: undefined, id: undefined })
        }
        isSelected={currentSelectedItem === -1}
      />
    )
  }

  return (
    <div className="w-full md:p-0 flex-1 flex flex-col min-h-0">
      <ProfileMedia
        className="mb-2 md:mx-auto w-15 h-15"
        canEdit={false}
        media={profileMedia}
      />
      <div className="h4 font-black text-white text-left md:text-center">
        {content.title}
      </div>
      <div className="mt-1 font-medium text-gray-200 text-left md:text-center">
        {content.description}
      </div>
      <div className="mt-3 w-[246px] self-center">
        <MultiToggle
          items={TOGGLE_ITEMS}
          value={currentMediaType}
          onToggle={val => onViewChange(val)}
        />
      </div>

      <div className="mt-3">
        <InfiniteScroll
          threshold={0}
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage}
          loader={<InfiniteScrollLoader key="spinner" />}
          useWindow={false}
          getScrollParent={() => document.getElementById(MODAL_OVERLAY_ID)}
        >
          <ul
            className={classNames(
              'grid grid-cols-3 gap-2 pt-[3px] pb-3 px-[3px] w-full',
              {
                'gap-y-4 pb-[43px]': hasChainAssets
              }
            )}
          >
            {currentMediaItems}
          </ul>
        </InfiniteScroll>
      </div>
    </div>
  )
}
