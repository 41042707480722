import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Field from '@/components/Field'
import { Button, Input } from '@/components'
import * as modalProps from '@/components/Modals/ModalV2/shared/props'
import { useWithdrawFunds } from '@/features/wallet/modals/withdraw/WithdrawFundsProvider'
import { NetworkConfirmation } from '@/features/wallet/components/NetworkConfirmation'
import { AvailableBalance } from '@/features/wallet/components/AvailableBalance'
import {
  WithdrawalLimit,
  WithdrawalSequence,
  WithdrawalStepConfiguration,
  WithdrawalStepProps,
  WithdrawalSteps
} from '@/features/wallet/modals/withdraw/types'

const defineForm = (minimum: WithdrawalLimit, maximum: WithdrawalLimit) =>
  z.object({
    amount: z.coerce
      .number()
      .min(minimum.value, {
        message: `You must withdraw a minimum of ${minimum.formatted}`
      })
      .max(maximum.value, {
        message: 'You cannot exceed your available balance'
      })
  })

const EnterAmountStep = ({
  sequence,
  ModalBody,
  ModalFooter
}: WithdrawalStepProps) => {
  const { transitionTo, withdrawal, setAmount, balance, minimum, maximum } =
    useWithdrawFunds()

  const form = useMemo(() => defineForm(minimum, maximum), [maximum, minimum])

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    setValue
  } = useForm<z.infer<typeof form>>({
    defaultValues: {
      amount: withdrawal.amount ?? ('' as any)
    },
    resolver: zodResolver(form),
    mode: 'onChange'
  })

  const submitForm = handleSubmit(formData => {
    const { amount } = formData
    setAmount(amount)
    transitionTo({
      step: 'finalizeStep',
      sequence
    })
  })

  const amount = watch('amount')
  const autoMaxEnabled = amount != balance

  const handleAutoMaxClick = () =>
    setValue('amount', balance, { shouldValidate: true })

  return (
    <form onSubmit={submitForm}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <AvailableBalance amount={amount} balance={balance} />
          <Field name="amount" errors={errors}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Input
                  hasError={!!errors?.amount}
                  format="currency"
                  currency="usdc"
                  inputMode="decimal"
                  autoComplete="off"
                  placeholder="0.00"
                  info={
                    <Button
                      disabled={!autoMaxEnabled}
                      theme={autoMaxEnabled ? 'blue' : 'gray-transparent'}
                      size="xs"
                      onClick={handleAutoMaxClick}
                    >
                      Max
                    </Button>
                  }
                  {...field}
                />
              )}
            />
          </Field>
          <NetworkConfirmation />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!isValid} {...modalProps.submit}>
          PREVIEW
        </Button>
      </ModalFooter>
    </form>
  )
}

EnterAmountStep.configure = (
  sequence: WithdrawalSequence
): WithdrawalStepConfiguration => {
  return {
    id: WithdrawalSteps.enterAmount,
    title: 'Enter Withdrawal Amount',
    onRetreat: () => sequence.goTo(WithdrawalSteps.enterAddress),
    view: props => (
      <EnterAmountStep sequence={sequence} {...props}></EnterAmountStep>
    )
  }
}

export { EnterAmountStep }
