import { cssFormattedSpacing } from '@/util/tailwindHelpers'
import { HeaderItem } from '@/features/wallet/components/HeaderItem'

export const NetworkConfirmation = () => {
  return (
    <div
      style={{
        '--containerXPadding': cssFormattedSpacing(3),
        '--containerYPadding': cssFormattedSpacing(2)
      }}
      className="border border-gray-700 rounded-2xl"
    >
      <div className="grid grid-cols-2 py-[var(--containerYPadding)] border-b border-inherit">
        <HeaderItem category="Token" label="USDC" icon="usdc" />
        <HeaderItem category="Network" label="Solana" icon="solana" />
      </div>

      <div className="py-[var(--containerYPadding)] px-[var(--containerXPadding)]">
        <p className="text-alert body-sm">
          By proceeding, you confirm the address above can receive USDC on the
          Solana network. Funds sent to an unsupported network will be lost and
          cannot be recovered by Droppp.
        </p>
      </div>
    </div>
  )
}
