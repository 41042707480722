import { ReactNode } from 'react'
import NiceModal from '@ebay/nice-modal-react'
// Constants & Helpers
import { MODAL_ID } from '@/constants/modalId'
import { paragraphClasses, footer, button, linkNewTab } from './shared/props'
import { Content as ContentGetUSDC } from './content/getUSDC'
// Components
import ModalSequence, {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from './ModalSequence'
import Button from '@/components/Button'
import classNames from 'classnames'
import GradientText from '@/components/GradientText'
import PoweredBySolana from '@/components/PoweredBySolana'

////////////////////////////////////////////////////////////////////////////////
// Types, Enums & Constants

enum ModalViewId {
  Introduction = 'information',
  WhatIsUSDC = 'what-is-usdc',
  GetUSDC = 'get-usdc',
  Security = 'security'
}

type ModalSequenceGoTo = {
  (target: string | number): void
  (arg0: ModalViewId): void
}

type FooterProps = {
  id: ModalViewId
  goTo: ModalSequenceGoTo
  onNext: () => void
}

////////////////////////////////////////////////////////////////////////////////
// Component

const NewWalletModal = NiceModal.create(() => {
  return (
    <ModalSequence
      id={MODAL_ID.wallet.newWalletAlert}
      modalId={MODAL_ID.wallet.newWalletAlert}
      init={({ goTo, next, previous, close }: IModalSequenceControllerProps) =>
        [
          {
            id: ModalViewId.Introduction,
            title: Content[ModalViewId.Introduction].title,
            header: Content[ModalViewId.Introduction].header,
            overlayHeaderOpaqueOnScroll: true,
            view: ({ ModalBody, ModalFooter }) => (
              <>
                <ModalBody>{Content[ModalViewId.Introduction].view}</ModalBody>
                <ModalFooter>
                  <Footer
                    id={ModalViewId.Introduction}
                    onNext={next}
                    goTo={goTo}
                  />
                </ModalFooter>
              </>
            )
          },
          {
            id: ModalViewId.WhatIsUSDC,
            onRetreat: () => previous(),
            title: Content[ModalViewId.WhatIsUSDC].title,
            header: Content[ModalViewId.WhatIsUSDC].header,
            overlayHeaderOpaqueOnScroll: true,
            view: ({ ModalBody, ModalFooter }) => (
              <>
                <ModalBody>{Content[ModalViewId.WhatIsUSDC].view}</ModalBody>
                <ModalFooter>
                  <Footer
                    id={ModalViewId.WhatIsUSDC}
                    onNext={next}
                    goTo={goTo}
                  />
                </ModalFooter>
              </>
            )
          },
          {
            id: ModalViewId.GetUSDC,
            onRetreat: () => previous(),
            title: Content[ModalViewId.GetUSDC].title,
            header: Content[ModalViewId.GetUSDC].header,
            overlayHeaderOpaqueOnScroll: true,
            view: ({ ModalBody, ModalFooter }) => (
              <>
                <ModalBody>{Content[ModalViewId.GetUSDC].view}</ModalBody>
                <ModalFooter>
                  <Footer id={ModalViewId.GetUSDC} onNext={next} goTo={goTo} />
                </ModalFooter>
              </>
            )
          },
          {
            id: ModalViewId.Security,
            onRetreat: () => previous(),
            title: Content[ModalViewId.Security].title,
            header: Content[ModalViewId.Security].header,
            overlayHeaderOpaqueOnScroll: true,
            view: ({ ModalBody, ModalFooter }) => (
              <>
                <ModalBody>{Content[ModalViewId.Security].view}</ModalBody>
                <ModalFooter>
                  <Footer
                    id={ModalViewId.Security}
                    onNext={close}
                    goTo={goTo}
                  />
                </ModalFooter>
              </>
            )
          }
        ] as IModalSequenceViewProps[]
      }
    />
  )
})

////////////////////////////////////////////////////////////////////////////////
// Helper Components

const Footer = ({ onNext, ...props }: FooterProps) => {
  return (
    <div {...footer} key={props.id}>
      <div className="flex flex-col w-full">
        <div className="w-full flex gap-2">
          <Button {...button} size="md" theme="white" onClick={onNext}>
            {Content[props.id].action}
          </Button>
        </div>
      </div>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Helper Content

const sharedImageProps = {
  width: 480,
  height: 300,
  path: 'global/modals/',
  className: 'aspect-[24/15]'
}

const Content: Record<
  ModalViewId,
  Omit<IModalSequenceViewProps, 'id'> & {
    dismiss?: string
    action: string
    view: ReactNode
  }
> = {
  [ModalViewId.Introduction]: {
    title: (
      <>
        <GradientText
          text="Introducing Droppp Wallet"
          className="h3"
          theme={'solana'}
        />
        <PoweredBySolana iconClassName="w-8 inline-block ml-[2px]" />
      </>
    ),
    header: {
      image: {
        ...sharedImageProps,
        id: 'wallet-mon_1',
        alt: 'Header image with a wallet, cards and license'
      }
    },
    view: (
      <>
        <p className={classNames(paragraphClasses, '-mt-1')}>
          Droppp has moved to a new wallet infrastructure that gives you full
          control of your funds. To shop on Droppp Marketplace, add funds to
          your Droppp Wallet by transferring USDC from a crypto exchange or
          external wallet. Identity verification is required to create a Droppp
          Wallet.
          <br />
          <br />
          Please be aware, all future USDC transfers will be made on the Solana
          network.
        </p>
      </>
    ),
    action: 'What is USDC?'
  },
  [ModalViewId.WhatIsUSDC]: {
    title: 'What is USDC?',
    header: {
      image: {
        ...sharedImageProps,
        id: 'what-is-usdc',
        alt: 'Header image with a monster sitting on a bench reading a tech magazine'
      }
    },
    view: (
      <p className={classNames(paragraphClasses, '-mt-1')}>
        USDC is a stablecoin designed to function like a U.S. Dollar. Droppp
        only supports USDC for funding your Droppp Wallet and making marketplace
        purchases.
      </p>
    ),
    action: 'Where Can I get USDC?'
  },
  [ModalViewId.GetUSDC]: {
    title: 'Where Can I get USDC?',
    header: {
      image: ContentGetUSDC.image
    },
    view: (
      <p className={classNames(paragraphClasses, '-mt-1')}>
        Adding funds to your Droppp Wallet is easy. Simply transfer USDC from an
        external crypto wallet or buy it from an exchange like 
        <a href="http://coinbase.com" {...linkNewTab}>
          Coinbase
        </a>
         and transfer it to your Droppp Wallet.
      </p>
    ),
    action: 'Learn About Security'
  },
  [ModalViewId.Security]: {
    title: 'We Take Security Seriously',
    header: {
      image: {
        ...sharedImageProps,
        id: 'safe-secure',
        alt: 'Header image with a coin surrounded by security lasers'
      }
    },
    view: (
      <p className={classNames(paragraphClasses, '-mt-1')}>
        Droppp takes security seriously, using multiple measures to protect your
        account, such as passkeys for approving transactions and two-factor
        authentication for withdrawals.
      </p>
    ),
    action: 'Done'
  }
}

export default NewWalletModal
