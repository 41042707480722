import { ModalChildrenWrappers } from '../BaseModal'
import PoweredBySolanaRightSlotContent from '../SolanaModal/PoweredBySolanaRightSlotContent'
import SolanaModal from '../SolanaModal/SolanaModal'

export interface Web3Transaction {
  id: string
  children: (layoutProps: ModalChildrenWrappers) => JSX.Element
}

const Web3TransactionModal = ({ id, children }: Web3Transaction) => {
  return (
    <SolanaModal
      id={id}
      headerRightSlot={
        <PoweredBySolanaRightSlotContent title="Droppp Wallet" />
      }
    >
      {layoutProps => children(layoutProps)}
    </SolanaModal>
  )
}

export default Web3TransactionModal
