export const walletPublicConfig = {
  tkApiBaseUrl: process.env['NEXT_PUBLIC_TK_BASE_URL'],
  tkOrganizationId: process.env['NEXT_PUBLIC_TK_ORGANIZATION_ID'],
  solUsdcToken: process.env['NEXT_PUBLIC_SOL_USDC_TOKEN'],
  solDpAddress: process.env['NEXT_PUBLIC_SOL_DP_ADDRESS'],
  solDpUsdcAddress: process.env['NEXT_PUBLIC_SOL_USDC_DP_ADDRESS']
}

export const walletPrivateConfig = {
  tkDpUserPublicKey: process.env['TK_API_PUBLIC_KEY'],
  tkDpUserPrivateKey: process.env['TK_API_PRIVATE_KEY'],
  tkDpEmailRecoveryHost: process.env['DP_ACCOUNT_HOST'],
  solRpcEndpointUrl: process.env['SOL_RPC_ENDPOINT_URL']
}
