import { ActionSection } from '@/components/ActionSection'
import { showModal } from '@/components/Modals/ModalV2'
import React, { ComponentPropsWithoutRef } from 'react'
import { SEED_PHRASE_MODAL_ID } from './constants'

interface Props extends ComponentPropsWithoutRef<'div'> {}

export default function SeedPhraseSection(props: Props) {
  return (
    <ActionSection
      {...props}
      sectionTitle="Seed Phrase"
      primaryAction={{
        label: 'View',
        action: () => showModal(SEED_PHRASE_MODAL_ID)
      }}
    >
      Use your seed phrase to import your Droppp Wallet into an external Solana
      wallet, like Phantom.
    </ActionSection>
  )
}
