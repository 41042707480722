import ModalSequence from '@/components/Modals/ModalV2/ModalSequence'
import { MODAL_ID } from '@/constants/modalId'
import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import { Reconnect } from './steps/reconnect'
import { VerifyEmail } from './steps/verifyEmail'

const ReconnectWalletModal = NiceModal.create<{}>(() => {
  return (
    <ModalSequence
      id={MODAL_ID.wallet.reconnect}
      modalId={MODAL_ID.wallet.reconnect}
      shouldShowProgress={false}
      init={props => [Reconnect(props), VerifyEmail(props)]}
    />
  )
})

export default ReconnectWalletModal
