import { AuthenticatedModalIds } from '@/constants/modalId'
import { useAuth } from '@/contexts/auth'
import useNextQueryParams from '@/hooks/useNextQueryParams'
import { isIn } from '@/util/typeHelper'
import NiceModal from '@ebay/nice-modal-react'
import { useEffect } from 'react'

const ModalIdParamHandler = () => {
  const { modalId, modalStep } = useNextQueryParams<{
    modalId?: string
    modalStep?: string
  }>()
  const { user } = useAuth()
  const isAuthenticatedModal = modalId && isIn(AuthenticatedModalIds, modalId)

  useEffect(() => {
    if (modalId && (!isAuthenticatedModal || (isAuthenticatedModal && user))) {
      const props: { modalStep?: string } = {}
      if (modalStep) props.modalStep = modalStep
      NiceModal.show(modalId, props)
    }
  }, [modalId, user?.uid])
  return null
}

export default ModalIdParamHandler
