export type Store = 'local' | 'session'

export enum StorageKey {
  DevDropContext = 'devDropContext',
  AddFundsRegionReviewed = 'addFundsRegionReviewed',
  ListOnMarketRegionReviewed = 'listOnMarketRegionReviewed',
  RegionInfo = 'regionInfo',
  CookieConsent = 'cookieConsent',
  MintListingsSort = 'mintListingsSort',
  SalesHistorySort = 'salesHistorySort',
  RedemptionOrder = 'RedemptionOrder',
  Wallet = 'dp:wallet'
}
