import { useState, useEffect } from 'react'

const TIME_REMAINING_INTERVAL = 1000

export const useCountdown = (
  initialSeconds: number,
  onFinished?: () => void
): number => {
  const [remaining, setRemaining] = useState(initialSeconds)

  useEffect(() => {
    const timer = setInterval(() => {
      setRemaining(prev => {
        const next = Math.max(prev - 1, 0)
        if (next === 0) clearInterval(timer)
        return next
      })
    }, TIME_REMAINING_INTERVAL)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (remaining <= 0) {
      onFinished?.()
      return
    }
  }, [remaining, onFinished])

  return remaining
}
