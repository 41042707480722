import NiceModal from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useAuth } from '@/contexts/auth'
import { MODAL_ID } from '@/constants/modalId'
import { Icon, Separator, Toast } from '@/components'
import RoundedBox from '@/components/RoundedBox'
import { Communications } from './Communications'
import { ActionSection } from '@/components/ActionSection'
import { classNames } from '@/util/tailwindHelpers'
import { MisatchedEmailInfo } from './MismatchedEmailAlert'

const roundedBoxClasses = 'border-1 border-gray-800'

export default function FormAccount() {
  const { user } = useAuth()
  const router = useRouter()

  useEffect(() => {
    const { confirmEmail, phraseUsed } = router.query
    confirmEmail &&
      Toast({
        type: 'success',
        title: 'Verified!',
        description: 'Your email address has been verified',
        onClose: () => {
          delete router.query.confirmEmail
          router.push(router)
        }
      })

    phraseUsed &&
      !user.mfa_type &&
      NiceModal.show(MODAL_ID.enable2FA, {
        phraseUsed,
        onCancel: () => {
          delete router.query.phraseUsed
          router.push(router)
        },
        onComplete: async () => {
          delete router.query.phraseUsed
          await router.push(router)
          router.reload()
        }
      })
  }, [router.query])

  if (!user) return null

  const changeEmailAction = !user.oauth_provider
    ? {
        label: 'Change',
        disabled: !!user.oauth_provider,
        action: () => NiceModal.show(MODAL_ID.changeEmail)
      }
    : null

  const twoStepAction = user.mfa_type
    ? {
        label: 'Disable',
        action: () => NiceModal.show(MODAL_ID.disable2FA)
      }
    : {
        label: 'Set Up',
        action: () =>
          NiceModal.show(MODAL_ID.enable2FA, {
            onComplete: () => router.reload()
          })
      }

  const twoStepContent = !user.mfa_type
    ? '2-Step Verification is enabled.'
    : 'Add an extra layer of security when signing in.'

  return (
    <div className="md:max-w-[600px] py-0 px-2 md:m-auto">
      <RoundedBox className={roundedBoxClasses}>
        <div className="h4 mb-3">Account</div>
        <div className="flex flex-col divide-y divide-defaultBorder *:py-3 first:*:pt-0 last:*:pb-0 mb-1">
          <div className="space-y-2">
            <ActionSection
              sectionTitle="Account Email"
              primaryAction={changeEmailAction}
            >
              The email address used to sign into Droppp.
            </ActionSection>
            <RoundedBox className="!px-[20px] !py-2 bg-gray-800 rounded-2xl min-h-7">
              <>
                <div className="flex justify-between flex-wrap gap-x-3 gap-y-1 items-center -mt-[1px] ">
                  <span className="text-gray-300">Droppp Account Email</span>
                  <span className="text-white">{user.email}</span>
                </div>
                {user.oauth_provider && (
                  <>
                    <Separator className="my-2" />
                    <div className="flex items-center gap-1 mb-1">
                      <Icon name={user.oauth_provider} />
                      <h4 className="utility-alt text-white">
                        {user.oauth_provider === 'apple'
                          ? ' Apple ID'
                          : ' Google Account'}
                      </h4>
                    </div>
                    <p className="body-sm text-white">
                      Your Droppp account was created using
                      {user.oauth_provider === 'apple'
                        ? ' Sign in with Apple. '
                        : ' Continue with Google. '}
                      To change your email/password, you will need to update
                      your
                      {user.oauth_provider === 'apple'
                        ? ' Apple ID on icloud.com.'
                        : ' Google Account on google.com.'}
                    </p>
                  </>
                )}
              </>
            </RoundedBox>
            <MisatchedEmailInfo />
          </div>

          {!user.oauth_provider && (
            <ActionSection
              sectionTitle="Password"
              primaryAction={{
                label: 'Change',
                action: () => NiceModal.show(MODAL_ID.changePassword)
              }}
            >
              Update your password
            </ActionSection>
          )}

          <ActionSection
            sectionTitle="2-Step Verification"
            primaryAction={twoStepAction}
          >
            {twoStepContent}
          </ActionSection>
        </div>
      </RoundedBox>
      <RoundedBox className={classNames('mt-3', roundedBoxClasses)}>
        <Communications />
      </RoundedBox>
    </div>
  )
}
