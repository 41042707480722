import { getDropNoListingType } from '@/util/dropHelpers'
import { CatalogItemDetail } from '@/api/resources/catalog/item/get/schema'
import { isUniqueProduct } from '@/util/assetHelpers'
import { NO_LISTING_MESSAGE_MAP } from '@/enum'
import ProductPrice from '@/components/ProductPrice'

const SubtitleLowestPrice = ({ item }: { item: CatalogItemDetail }) => {
  const isForSale = item.listing_price

  return (
    <div className="pricing-xl text-gray-300">
      {isForSale ? (
        <ProductPrice
          price={item.listing_price}
          hideAndUpSuffix={isUniqueProduct(item)}
          listingsCount={item.listings_available}
        />
      ) : (
        NO_LISTING_MESSAGE_MAP[getDropNoListingType(item)]
      )}
    </div>
  )
}

export default SubtitleLowestPrice
