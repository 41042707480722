import React from 'react'
import { useWallet } from '../providers/wallet'
import DropppWalletCard from '@/components/WalletCard/DropppWalletCard'
import { Button, Toast } from '@/components'
import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { ButtonLink } from '@/components/Button'
import { WalletActions } from './WalletActions'

export default function FundsSection() {
  const { wallet, status, balance } = useWallet()

  const isConnected = status === 'connected'

  const handleReconnectClick = () => showModal(MODAL_ID.wallet.reconnect)

  return (
    <div className="flex flex-col gap-3">
      <div className="space-y-2">
        <h2 className="h4">Funds</h2>
        {isConnected ? (
          <p className="text-gray-300">
            Add USDC to your Droppp Wallet to make purchases on Droppp
            Marketplace.
          </p>
        ) : (
          <Toast type="attention" inline>
            Your wallet is disconnected and needs to be reconnected to purchase
            items on Droppp Marketplace and withdraw funds.
          </Toast>
        )}
      </div>

      {status !== 'none' && (
        <DropppWalletCard
          account={{
            address: wallet.address,
            display_address: wallet.display_address
          }}
          balance={balance}
          status={status}
        />
      )}

      {isConnected ? (
        <WalletActions />
      ) : (
        <>
          <Button theme="solana" onClick={handleReconnectClick}>
            Reconnect
          </Button>
          <ButtonLink theme="clean-blue" href="/support">
            Need help? Contact Support
          </ButtonLink>
        </>
      )}
    </div>
  )
}
