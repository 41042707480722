import Button from '@/components/Button'
import Icon from '@/components/Icon'
import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { useAuth } from '@/contexts/auth'
import { useWallet } from '@/features/wallet/providers/wallet'
import { designSystem } from '@/util/tailwindHelpers'
import React from 'react'

export const MisatchedEmailInfo = () => {
  const { wallet } = useWallet()
  const {
    user: { email, oauth_provider }
  } = useAuth()

  if (oauth_provider || !wallet || email === wallet.tk_email) return null

  return (
    <div className="flex gap-1">
      <Icon
        name="informationalFilled"
        style={{ '--iconTextColor': designSystem.colors.white }}
        className="text-blue flex-shrink-0 mt-[2px]"
      />
      <p className="body-sm text-gray-300">
        Wallet recovery email (
        <span className="text-white">{wallet.tk_email}</span>) does not match
        your account email. You may update your account email to match the
        recovery email, or you can leave them as they are. Both options are
        perfectly acceptable.{' '}
        <Button
          onClick={() => showModal(MODAL_ID.wallet.manage)}
          theme="clean"
          className="inline-link"
        >
          Change
        </Button>
      </p>
    </div>
  )
}
