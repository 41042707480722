import { z } from 'zod'
import { registeredWeb3WalletSchema } from '@/api/resources/user/web3/shared'
import { successResponseSchema } from '@/api/resources/shared/success'

export const web3SetInputSchema = registeredWeb3WalletSchema.extend({
  tk_email: z.string().optional(),
  migration_step: z.number().optional()
})
export const web3SetResponseSchema = successResponseSchema.extend({
  data: registeredWeb3WalletSchema
})

export type Web3SetInput = z.infer<typeof web3SetInputSchema>
export type Web3SetResponse = z.infer<typeof web3SetResponseSchema>
