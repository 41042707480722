import { classNames } from '@/util/tailwindHelpers'
import React from 'react'
import { ReactNode } from 'react'

interface Props extends React.ComponentPropsWithRef<'div'> {
  className?: string
  size?: 'sm' | 'default'
  children: ReactNode
}

export default function RoundedBox({
  className,
  size = 'default',
  children,
  style
}: Props) {
  return (
    <div
      style={{ '--separatorColor': 'var(--color-gray-700)', ...style }}
      className={classNames(
        'p-[var(--boxPadding)] bg-gray-850 rounded-3xl',
        {
          '!p-3 !rounded-2xl': size === 'sm'
        },
        className
      )}
    >
      {children}
    </div>
  )
}
