import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'
import { TypedQueryOptions } from '@/api/core/query/options'
import { ApiError } from '@/api/core/errors'
import {
  Web3GetResponse,
  web3GetResponseSchema
} from '@/api/resources/user/web3/get/schema'

export const path = '/user/web3/get'

export const getWeb3Wallet = async (
  queryContext?: Partial<QueryFunctionContext>
): Promise<Web3GetResponse> =>
  post<Web3GetResponse>(buildApiUrl(path), queryContext, web3GetResponseSchema)

export const web3WalletQueryKey = [path] as const

export const useWeb3WalletQuery = (
  queryOptions?: TypedQueryOptions<Web3GetResponse>
) =>
  useQuery<Web3GetResponse, ApiError>(
    web3WalletQueryKey,
    queryContext => getWeb3Wallet(queryContext),
    queryOptions
  )
