import { classNames } from '@/util/tailwindHelpers'
import Icon, { Icons } from '@/components/Icon'
import Button from '@/components/Button'
import { useWallet } from '../providers/wallet'
import { useRef, useState } from 'react'
import { Loading } from '@/components'
import { TK_EXPORT_WALLET_IFRAME } from '../utils/turnkey'
import { notifyWalletError } from '../utils/notify'

const RevealSeedPhrase = () => {
  const [isFrameHidden, setIsFrameHidden] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { exportWallet } = useWallet()
  const iframeContainerRef = useRef<HTMLDivElement>(null)

  const handleExportClick = async () => {
    try {
      setIsLoading(true)
      const success = await exportWallet()
      if (success) setIsFrameHidden(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notifyWalletError(error)
    }
  }

  return (
    <>
      <div className="space-y-2 mb-3">
        <Section title="Your Keys, Your Coins" icon="key">
          Your seed phrase is the key to the funds held in your wallet. You can
          use it to connect your Droppp Wallet to an external Solana wallet,
          like Phantom. You will also need this to recover funds if you lose
          access to your Droppp account.
        </Section>
        <Section title="Do not share your phrase" icon="alert">
          Never share your seed phrase with anyone.{' '}
          <span className="text-white">
            Droppp staff do not have access to it and will never ask for it.
          </span>{' '}
          If someone requests your seed phrase in a private message, such as on
          Discord,{' '}
          <span className="text-alert">
            it's a scam designed to steal your funds
          </span>
          .
        </Section>
        <Section title="Keep it secure & private" icon="hideFilled">
          Copy your seed phrase and store it in a safe, secure location that
          only you can access, such as a private encrypted password manager or
          by writing it down offline.
        </Section>
      </div>
      <h6 className="h6">Your Seed Phrase</h6>
      <div className="mt-3 min-h-[90px] rounded-3xl relative bg-gray-850 border-solana">
        {isFrameHidden && (
          <>
            <div className="absolute inset-0 flex items-center text-left p-3 opacity-50 leading-[24px] blur-sm">
              <p className="line-clamp-2">
                this fake demonstration seed phrase is completely invalid not
                for actual use
              </p>
            </div>
            <div className="absolute inset-0">
              {!isLoading && (
                <Button
                  theme="clean"
                  loading={isLoading}
                  className={classNames('w-full h-full p-3 body-md', {
                    hidden: !isFrameHidden
                  })}
                  onClick={handleExportClick}
                >
                  <div className="w-full h-full flex justify-center items-center gap-1">
                    <Icon name="visible" />
                    Show Seed Phrase
                  </div>
                </Button>
              )}
              {isLoading && <Loading size="small" theme="solanaRainbow" />}
            </div>
          </>
        )}
        <div
          className={classNames('*:w-full *:h-[60px] sm:*:h-[40px] p-3', {
            hidden: isFrameHidden
          })}
          ref={iframeContainerRef}
          id={TK_EXPORT_WALLET_IFRAME.containerId}
        />
      </div>
    </>
  )
}

export default RevealSeedPhrase

interface SectionProps {
  className?: string
  title: string
  icon: Icons
  children: React.ReactNode
}

const Section = ({ className, title, icon, children }: SectionProps) => {
  return (
    <div className={classNames('space-y-1', className)}>
      <div className="flex items-center gap-1">
        <Icon name={icon} className="w-3 h-3 text-alert" />
        <h2 className="h6">{title}</h2>
      </div>
      <div className="text-gray-300">{children}</div>
    </div>
  )
}
