import SolanaSpinner from '@/components/SolanaSpinner'

const Header = ({
  title = 'Approve Request',
  description
}: {
  title?: string
  description: string
}) => {
  return (
    <div className="space-y-3">
      <SolanaSpinner icon="dpLogo" className="mx-auto" />
      <div className="flex flex-col gap-2 items-center">
        <h1 className="h4">{title}</h1>
        <p className="body-md text-gray-300 text-center text-balance">
          {description}
        </p>
      </div>
    </div>
  )
}

export default Header
