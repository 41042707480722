import { z } from 'zod'
import { web3WalletSchema } from '@/api/resources/user/web3/shared'
import { successResponseSchema } from '@/api/resources/shared/success'

export const web3GetResponseSchema = successResponseSchema.extend({
  web3data: web3WalletSchema.nullable(),
  migration_needed: z.boolean().nullable(),
  migration_step: z.number().nullable()
})

export type Web3GetResponse = z.infer<typeof web3GetResponseSchema>
