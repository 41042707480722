import React from 'react'

export const TransferWarning = () => {
  return (
    <p className="text-alert body-sm">
      Droppp only supports USDC transfers. Sending any other cryptocurrency will
      result in a loss of funds that Droppp cannot recover.
    </p>
  )
}
