import { createContext, ReactNode, useContext, useState } from 'react'
import {
  defaultDeposit,
  Deposit,
  DepositContextType,
  DepositTransition
} from '@/features/wallet/modals/deposit/types'
import { useWallet } from '@/features/wallet/providers/wallet'

export const DepositFundsContext = createContext<
  DepositContextType | undefined
>(undefined)

export const useDepositFunds = () => {
  const context = useContext(DepositFundsContext)

  if (context === undefined)
    throw new Error(
      'useDepositFunds must be used within a DepositFundsProvider'
    )

  return context
}

type DepositFundsProviderProps = {
  children: ReactNode
}

export const DepositFundsProvider = ({
  children
}: DepositFundsProviderProps) => {
  const [deposit, setDeposit] = useState<Deposit>(defaultDeposit)

  const { hasDeposited } = useWallet()

  const transitionTo = <T extends DepositTransition>(transition: T) => {
    const { sequence } = transition
    sequence.goTo(transition.step)
  }

  const updateDeposit = (update: Partial<Deposit>) =>
    setDeposit(previous => ({ ...previous, ...update }))

  const value = {
    deposit,
    hasDeposited,
    updateDeposit,
    transitionTo
  }

  return (
    <DepositFundsContext.Provider value={value}>
      {children}
    </DepositFundsContext.Provider>
  )
}
