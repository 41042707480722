import { BadgeProps } from '.'

export const iconClass = (size: BadgeProps['size']) => {
  switch (size) {
    case 'lg':
      return '!mr-[4px] -ml-[2px]'
    case 'fluid-sm':
      return '!mr-0'
    default:
      return '-ml-[.5em]'
  }
}
