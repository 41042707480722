import RoundedBox from '@/components/RoundedBox'
import { useWallet } from '@/features/wallet/providers/wallet'
import CreateWalletSection from './components/CreateWalletSection'
import FundsSection from './components/FundsSection'
// import { MODAL_ID } from '@/constants/modalId'
// import { showModal } from '@/components/Modals/ModalV2'
// import { Button } from '@/components'

export const WalletPanel = () => {
  const { migrationNeeded, wallet, isWalletLoading } = useWallet()

  if (isWalletLoading) return null

  const showCreateWallet = migrationNeeded || !wallet

  return (
    <RoundedBox>
      {/* This is temporarily used to trigger getting actual eth or polygon addresses to be able to show step 3 of the migrate flow */}
      {/* <Button
        theme="secondary"
        size="xs"
        onClick={() => showModal(MODAL_ID.wallet.addFunds)}
      >
        Get Deposit Address
      </Button> */}
      {showCreateWallet ? <CreateWalletSection /> : <FundsSection />}
    </RoundedBox>
  )
}
