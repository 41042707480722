import React from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from '../../../ModalSequence'
import { ModalFooterButtons } from '../../../ModalFooterButtons'
import Link from 'next/link'

export const backupSeedPhrase = (
  sequenceProps: IModalSequenceControllerProps
): IModalSequenceViewProps => {
  return {
    id: 'backupSeedPhrase',
    title: 'Top Secret: Your Seed Phrase',
    overlayHeaderOpaqueOnScroll: true,
    header: {
      image: {
        path: 'pages/wallet/wallet-modal/',
        id: 'top_secret',
        alt: 'Top Secret: Your Seed Phrase',
        width: 480,
        height: 300
      }
    },
    view: ({ ModalBody, ModalFooter }) => {
      const { next } = sequenceProps
      return (
        <>
          <ModalBody>
            <p className="text-gray-300">
              A seed phrase is the ultimate key to your Solana blockchain
              wallet. On Droppp, you don’t need to interact directly with your
              seed phrase because it’s secured by a passkey.
              <br />
              <br /> However, since you have full control of your wallet and
              Droppp cannot access your funds, it’s crucial to back up your seed
              phrase. This ensures you can recover your wallet and withdraw your
              funds using an external Solana wallet app if you ever lose access
              to your Droppp account.
              <br />
              <br />
              For more information about your seed phrase see our{' '}
              <Link href="/faq" target="_blank" className="inline-link">
                FAQs
              </Link>{' '}
              .
            </p>
          </ModalBody>
          <ModalFooter>
            <ModalFooterButtons
              primaryButton={{
                onClick: next,
                label: 'Download Seed Phrase'
              }}
            />
          </ModalFooter>
        </>
      )
    }
  }
}
