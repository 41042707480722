import React, { useState } from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from '@/components/Modals/ModalV2/ModalSequence'
import {
  notifyWalletConnected,
  notifyWalletError
} from '@/features/wallet/utils/notify'
import { ModalFooterButtons } from '@/components/Modals/ModalV2/ModalFooterButtons'
import { useWallet } from '@/features/wallet/providers/wallet'
import { Toast } from '@/components'

const notifyEmailSent = (tk_email: string) =>
  Toast({
    type: 'success',
    autoClose: 5000,
    description: `Email sent to ${tk_email}`
  })

export const Reconnect = (
  sequenceProps: IModalSequenceControllerProps
): IModalSequenceViewProps => {
  const {
    reconnectWallet,
    startWalletRecovery,
    wallet: { tk_email }
  } = useWallet()
  const [isPrimaryButtonLoading, setIsPrimaryButtonLoading] = useState(false)
  const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] =
    useState(false)

  const handleReconnectClick = async () => {
    try {
      await reconnectWallet()
      notifyWalletConnected()
      sequenceProps.close()
    } catch (err: unknown) {
      notifyWalletError(err)
    }
  }

  const handleRecoverClick = async () => {
    try {
      setIsSecondaryButtonLoading(true)
      await startWalletRecovery()
      sequenceProps.next()
      notifyEmailSent(tk_email)
    } catch (err: unknown) {
      notifyWalletError(err)
    } finally {
      setIsSecondaryButtonLoading(false)
    }
  }

  return {
    id: 'reconnect',
    title: 'Reconnect Droppp Wallet',
    overlayHeaderOpaqueOnScroll: true,
    header: {
      image: {
        path: 'pages/wallet/migration/',
        id: 'reconnect-wallet',
        alt: 'header image',
        width: 480,
        height: 300
      }
    },
    view: ({ ModalBody, ModalFooter }) => {
      return (
        <>
          <ModalBody>
            <p className="text-gray-300">
              Your wallet must be connected to make purchases and withdraw
              funds. If this device doesn’t have a synced passkey, click “Can’t
              Connect?” to add one.
            </p>
          </ModalBody>
          <ModalFooter className="space-y-3">
            <ModalFooterButtons
              isPrimaryButtonLoading={isPrimaryButtonLoading}
              setIsPrimaryButtonLoading={setIsPrimaryButtonLoading}
              primaryButton={{
                theme: 'solana',
                label: 'Reconnect',
                onClick: handleReconnectClick
              }}
              secondaryButton={{
                theme: 'gray',
                label: "Can't Connect?",
                loading: isSecondaryButtonLoading,
                onClick: handleRecoverClick
              }}
            />
          </ModalFooter>
        </>
      )
    }
  }
}
