import DefaultModal, { hideModal } from '@/components/Modals/ModalV2'
import { SEED_PHRASE_MODAL_ID } from './constants'
import RevealSeedPhrase from '../components/RevealSeedPhrase'

export const SeedPhraseModal = () => {
  return (
    <DefaultModal
      id={SEED_PHRASE_MODAL_ID}
      title="Seed Phrase"
      onRetreat={() => hideModal(SEED_PHRASE_MODAL_ID)}
    >
      <RevealSeedPhrase />
    </DefaultModal>
  )
}
