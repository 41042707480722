import { useForm } from 'react-hook-form'
import React from 'react'
import { Button } from '@/components'
import Field from '@/components/Field'
import { useWithdrawFunds } from '@/features/wallet/modals/withdraw/WithdrawFundsProvider'
import * as modalProps from '@/components/Modals/ModalV2/shared/props'
import ControlledWalletAddressInput from '@/features/wallet/components/ControlledWalletAddressInput'
import { ControlledConfirmation } from '@/components/AcknowledgeBox/Confirmation'
import {
  WithdrawalSequence,
  WithdrawalStepConfiguration,
  WithdrawalStepProps,
  WithdrawalSteps
} from '@/features/wallet/modals/withdraw/types'
import InlineNetworkIcon from '@/features/wallet/components/InlineNetworkIcon'

type AddressForm = {
  address: string
  confirmed: true
}

const EnterAddressStep = ({
  sequence,
  ModalBody,
  ModalFooter
}: WithdrawalStepProps) => {
  const {
    transitionTo,
    setAddress,
    withdrawal: { address }
  } = useWithdrawFunds()

  const {
    handleSubmit,
    formState: { isValid, errors },
    control
  } = useForm<AddressForm>({
    defaultValues: {
      address: address || ''
    },
    mode: 'onChange'
  })

  const submitForm = handleSubmit(formData => {
    const { address } = formData
    setAddress(address)
    transitionTo({
      step: 'enterAmountStep',
      sequence
    })
  })

  return (
    <form onSubmit={submitForm}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <p className="text-gray-300">
            From your crypto exchange or external Solana wallet, copy the
            address and paste it below.
          </p>
          <Field name="address" errors={errors} label="Solana Network Address">
            <ControlledWalletAddressInput
              name="address"
              control={control as any}
            />
          </Field>
          <ControlledConfirmation
            className="bg-gray-800"
            name="confirmed"
            control={control}
            required
          >
            <span className="leading-[22px]">
              Yes, I’ve confirmed that the address is correct and capable of
              receiving <InlineNetworkIcon title="USDC" icon="usdc" /> over the{' '}
              <InlineNetworkIcon title="Solana" icon="solana" /> network.
            </span>
          </ControlledConfirmation>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!isValid} {...modalProps.submit}>
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

EnterAddressStep.configure = (
  sequence: WithdrawalSequence
): WithdrawalStepConfiguration => {
  return {
    id: WithdrawalSteps.enterAddress,
    title: 'Enter Recipient Address',
    onRetreat: () => sequence.previous(),
    view: props => (
      <EnterAddressStep sequence={sequence} {...props}></EnterAddressStep>
    )
  }
}

export { EnterAddressStep }
