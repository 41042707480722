import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps,
  ModalSequenceChildren
} from '@/components/Modals/ModalV2/ModalSequence'

export const DepositSteps = {
  addUsdcStep: 'addUsdcStep',
  confirmUsdcStep: 'confirmUsdcStep',
  howToUsdcStep: 'howToUsdcStep',
  solanaConfirmStep: 'solanaConfirmStep',
  solanaDepositStep: 'solanaDepositStep',
  solanaDoneStep: 'solanaDoneStep'
} as const

export type DepositStep = (typeof DepositSteps)[keyof typeof DepositSteps]

export type DepositOptionValues = {
  address?: string
  barcode?: string
}

export type DepositOptionType = 'copyAddress' | 'scanQRCode'

export type Deposit = {
  hasUsdc: boolean
  confirmedUsdc: boolean
  confirmedSolana: boolean
  selectedOption: DepositOptionType
  depositOptionValues: DepositOptionValues
}

export const defaultDeposit: Deposit = {
  hasUsdc: false,
  confirmedUsdc: false,
  confirmedSolana: false,
  selectedOption: 'copyAddress',
  depositOptionValues: null
}

export type DepositSequence = IModalSequenceControllerProps
export type DepositStepConfiguration = IModalSequenceViewProps

export type DepositTransition = {
  step: DepositStep
  sequence: DepositSequence
}

export type DepositContextType = {
  deposit: Deposit
  hasDeposited: boolean
  transitionTo: (transition: DepositTransition) => void
  updateDeposit: (deposit: Partial<Deposit>) => void
}

export type DepositStepProps = {
  sequence: DepositSequence
} & ModalSequenceChildren
