import NiceModal from '@ebay/nice-modal-react'
import { AddUsdcStep } from '@/features/wallet/modals/deposit/steps/AddUsdcStep'
import { DepositSequence } from '@/features/wallet/modals/deposit/types'
import {
  DepositFundsProvider,
  useDepositFunds
} from '@/features/wallet/modals/deposit/DepositFundsProvider'
import ModalSequence from '@/components/Modals/ModalV2/ModalSequence'
import { MODAL_ID } from '@/constants/modalId'
import { HowToUsdcStep } from '@/features/wallet/modals/deposit/steps/HowToUsdcStep'
import { ConfirmUsdcStep } from '@/features/wallet/modals/deposit/steps/ConfirmUsdcStep'
import { SolanaConfirmStep } from '@/features/wallet/modals/deposit/steps/SolanaConfirmStep'
import { SolanaDepositStep } from '@/features/wallet/modals/deposit/steps/SolanaDepositStep'
import { SolanaDoneStep } from '@/features/wallet/modals/deposit/steps/SolanaDoneStep'

const DepositModalSequence = () => {
  const context = useDepositFunds()

  const initializeSequence = (sequence: DepositSequence) => {
    return [
      AddUsdcStep.configure(sequence),
      HowToUsdcStep.configure(context, sequence),
      ConfirmUsdcStep.configure(context, sequence),
      SolanaConfirmStep.configure(context, sequence),
      SolanaDepositStep.configure(context, sequence),
      SolanaDoneStep.configure(context, sequence)
    ]
  }

  return (
    <ModalSequence
      id={MODAL_ID.web3.deposit}
      modalId={MODAL_ID.web3.deposit}
      init={initializeSequence}
      shouldShowProgress={false}
    />
  )
}

export const DepositFundsModal = NiceModal.create(() => {
  return (
    <DepositFundsProvider>
      <DepositModalSequence />
    </DepositFundsProvider>
  )
})
