import { ComponentPropsWithoutRef, ReactNode } from 'react'
import Button from './Button'

interface ActionSectionProps extends ComponentPropsWithoutRef<'div'> {
  sectionTitle: ReactNode
  children: ReactNode
  primaryAction?: {
    label: string
    disabled?: boolean
    action: () => void
  }
}

export const ActionSection = ({
  sectionTitle,
  children,
  primaryAction,
  ...rest
}: ActionSectionProps) => {
  return (
    <div {...rest}>
      <h5 className="h7 mb-1">{sectionTitle}</h5>
      <div className="flex justify-between items-center gap-5">
        <div className="text-gray-300">{children}</div>
        {primaryAction && (
          <Button
            theme="clean-blue"
            disabled={primaryAction.disabled}
            onClick={primaryAction.action}
          >
            {primaryAction.label}
          </Button>
        )}
      </div>
    </div>
  )
}
