import CloudinaryImage from '@/components/CloudinaryImage'

export const NoWalletImage = () => (
  <div>
    <div className="relative aspect-[1053/735] -my-half">
      <CloudinaryImage
        layout="fill"
        imageId={'wallet_illustration'}
        path={'pages/wallet/'}
      />
    </div>
  </div>
)
