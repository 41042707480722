import React from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps,
  ModalSequenceChildren
} from '../../../ModalSequence'
import Link from 'next/link'
import { ModalFooterButtons } from '../../../ModalFooterButtons'
import { ControlledConfirmation } from '@/components/AcknowledgeBox/Confirmation'
import { useForm } from 'react-hook-form'

export const welcome = (
  sequenceProps: IModalSequenceControllerProps
): IModalSequenceViewProps => {
  return {
    id: 'welcome',
    title: 'Welcome to the new droppp wallet',
    overlayHeaderOpaqueOnScroll: true,
    header: {
      image: {
        path: 'pages/wallet/wallet-modal/',
        id: 'welcome_to_dw',
        alt: 'Droppp Wallet',
        width: 480,
        height: 300
      }
    },
    view: props => <Body {...{ next: sequenceProps.next, ...props }} />
  }
}

type BodyProps = ModalSequenceChildren &
  Pick<IModalSequenceControllerProps, 'next'>

const Body = ({ ModalBody, ModalFooter, next }: BodyProps) => {
  const termsAndConditionsInput = 'acknowledge'

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<{ [termsAndConditionsInput]: boolean }>()

  return (
    <form onSubmit={handleSubmit(next)}>
      <ModalBody>
        <p className="text-gray-300">
          Once set up, your wallet for managing funds on Droppp Marketplace will
          be powered by the Solana blockchain† and fully owned by you. This
          guide will walk you through creating your wallet and setting up a
          passkey to approve transactions.<br></br>
          <br></br>
          <span className="body-sm">
            † Integration provided by{' '}
            <Link
              href="https://www.turnkey.com"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-link"
            >
              Turnkey
            </Link>{' '}
          </span>
        </p>
        <ControlledConfirmation
          control={control}
          name={termsAndConditionsInput}
          required
          className="bg-gray-800 mt-2"
        >
          I acknowledge the{' '}
          <Link href="/terms" target="_blank" className="inline-link">
            Terms and Conditions
          </Link>
          .
        </ControlledConfirmation>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons
          primaryButton={{
            type: 'submit',
            onClick: next,
            label: 'Create Wallet',
            disabled: !isValid
          }}
        />
      </ModalFooter>
    </form>
  )
}
