import React from 'react'
import { dateToLocal } from '@/util/timeHelpers'
import { Separator } from '@/components'
import DetailHeader from '@/components/ActivityDetail/DetailHeader'
import PurchaseItems from '@/components/ActivityDetail/PurchaseItems'
import { ActionDetailProps } from '..'
import ActivityStatusBadge from '@/components/Activity/ActivityStatusBadge'
import { USDC } from '@/components/USDC'

const Sale = ({ id, activity }: ActionDetailProps) => {
  const { time_created, info, listing, tx_chain_status } = activity
  const { asset, net_amount, listing_price, net_fee } = listing
  const { mint_num, media } = asset

  const details = [
    {
      label: 'Status',
      value: <ActivityStatusBadge status={tx_chain_status} />
    },
    {
      label: 'Date',
      value: dateToLocal({ date: time_created })
    },
    {
      label: 'Transaction ID',
      value: `#${id}`
    }
  ]
  const items = [
    {
      name: info,
      detail: `#${mint_num}`,
      media
    }
  ]

  return (
    <>
      <DetailHeader details={details} />
      <Separator className="my-3" />
      <PurchaseItems
        items={items}
        subTotal={<USDC price={listing_price} suffixClassName="body-xs" />}
        fee={<USDC price={net_fee} suffixClassName="body-xs" />}
        total={<USDC price={net_amount} suffixClassName="pricing-sm" />}
        status={tx_chain_status}
        pendingMessage="This sale is pending confirmation on the blockchain. If it fails, it will return back to your inventory as an active listing."
      />
    </>
  )
}

export default Sale
