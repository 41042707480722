import { ReactNode } from 'react'
import { classNames } from '@/util/tailwindHelpers'

interface Props {
  className?: string
  property: string
  value: ReactNode
}

const LineItem = ({ className, property, value }: Props) => {
  return (
    <li
      className={classNames('body-sm flex justify-between w-full', className)}
    >
      <div className="text-gray-300">{property}</div>
      <div>{value}</div>
    </li>
  )
}

export default LineItem
