import { postJSON } from '@/api/core/http/post'

import {
  Web3RecoverInput,
  Web3RecoverResponse,
  web3RecoverResponseSchema
} from './schema'

const path = '/user/web3/recover/'

export const recoverWeb3Wallet = async (
  input: Web3RecoverInput
): Promise<Web3RecoverResponse> =>
  postJSON<Web3RecoverResponse>(path, input, {}, web3RecoverResponseSchema)
