import DefaultModal from '@/components/Modals/ModalV2/index'
import React from 'react'
import { MODAL_ID } from '@/constants/modalId'
import RecoveryEmailSection from './RecoveryEmailSection'
import SeedPhraseSection from './SeedPhraseSection'
import RemoveWalletSection from './RemoveWalletSection'
import { SeedPhraseModal } from './SeedPhraseModal'
import { WalletRecoveryEmailModal } from './RecoveryEmailModal'

type Props = {}

export default function ManageWallet({}: Props) {
  return (
    <>
      <DefaultModal title="Manage Wallet" id={MODAL_ID.wallet.manage}>
        {/* USEFUL DIVIDE WRAPPER CLASSES */}
        <div className="flex flex-col divide-y divide-defaultBorder *:py-3 first:*:pt-0 last:*:pb-0 mb-1">
          <SeedPhraseSection />
          <RecoveryEmailSection />
          <RemoveWalletSection />
        </div>
      </DefaultModal>

      {/* Secondary Modals unique to Manage Wallet */}
      <SeedPhraseModal />
      <WalletRecoveryEmailModal />
    </>
  )
}
