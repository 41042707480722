import NiceModal from '@ebay/nice-modal-react'
import { MODAL_ID } from '@/constants/modalId'
import ModalSequence from '@/components/Modals/ModalV2/ModalSequence'
import { WithdrawFundsProvider } from '@/features/wallet/modals/withdraw/WithdrawFundsProvider'
import { WithdrawalSequence } from '@/features/wallet/modals/withdraw/types'
import { CryptoExchangeStep } from '@/features/wallet/modals/withdraw/steps/CryptoExchangeStep'
import { EnterAddressStep } from '@/features/wallet/modals/withdraw/steps/EnterAddressStep'
import { EnterAmountStep } from '@/features/wallet/modals/withdraw/steps/EnterAmountStep'
import { FinalizeStep } from '@/features/wallet/modals/withdraw/steps/FinalizeStep'

export const WithdrawFundsModal = NiceModal.create(() => {
  const initializeSequence = (sequence: WithdrawalSequence) => {
    return [
      CryptoExchangeStep.configure(sequence),
      EnterAddressStep.configure(sequence),
      EnterAmountStep.configure(sequence),
      FinalizeStep.configure(sequence)
    ]
  }

  return (
    <WithdrawFundsProvider>
      <ModalSequence
        id={MODAL_ID.web3.withdrawal}
        modalId={MODAL_ID.web3.withdrawal}
        init={initializeSequence}
        shouldShowProgress={false}
      />
    </WithdrawFundsProvider>
  )
})
