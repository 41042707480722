import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer
} from 'react'
import {
  WithdrawalContextType,
  WithdrawalTransition
} from '@/features/wallet/modals/withdraw/types'
import {
  defaultWithdrawal,
  withdrawalReducer
} from '@/features/wallet/modals/withdraw/reducer'
import { formatCurrency } from '@/util/currencyHelpers'
import { useWallet } from '@/features/wallet/providers/wallet'
import { withdrawUserCrypto } from '@/api/resources/user/wallet/crypto/withdraw'
import { TransactionParty } from '@/components/Modals/ModalV2/Web3Transaction/components/Parties'
import { getTransactionSigner } from '@/features/wallet/utils/turnkey'
import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { getSolanaRecentBlockhash } from '@/api/resources/solana/recent'
import {
  buildWithdrawal,
  encodeTransaction,
  getDisplayAddress,
  getUsdcAccount
} from '@/util/solanaHelper'
import { submitWeb3Withdrawal } from '@/api/resources/user/web3/withdrawal'
import { WithdrawalIntent } from '@/components/Modals/ModalV2/Web3Transaction/WithdrawalApproval'
import { useAuth } from '@/contexts/auth'
import { checkSolanaAccountExists } from '@/api/resources/solana/exists'

const MIN_WITHDRAW_AMOUNT = 0.01

export const WithdrawFundsContext = createContext<
  WithdrawalContextType | undefined
>(undefined)

export const useWithdrawFunds = () => {
  const context = useContext(WithdrawFundsContext)

  if (context === undefined)
    throw new Error(
      'useWithdrawFunds must be used within a WithdrawFundsProvider'
    )

  return context
}

type WithdrawFundsProviderProps = {
  children: ReactNode
}

export const WithdrawFundsProvider = ({
  children
}: WithdrawFundsProviderProps) => {
  const { user } = useAuth()
  const { balance, withdrawalFee, wallet } = useWallet()

  const [withdrawal, dispatch] = useReducer(
    withdrawalReducer,
    defaultWithdrawal()
  )

  const startWithdrawal = async (mfaCode?: string) => {
    return await withdrawUserCrypto({
      ...withdrawal,
      chain: 'SOL',
      code: mfaCode
    })
  }

  const finishWithdrawal = async (txnHash: string) => {
    const result = await withdrawUserCrypto({
      ...withdrawal,
      chain: 'SOL',
      transaction_hash: txnHash
    })

    return result.expected_balance
  }

  const approveWithdrawal = async (
    amount: number,
    from: TransactionParty,
    to: TransactionParty
  ): Promise<string | undefined> => {
    const withdrawalIntent: WithdrawalIntent = {
      amount,
      from,
      to
    }

    return new Promise(resolve => {
      const handleApproved = (credentialBundle: string) =>
        resolve(credentialBundle)

      const handleCancel = () => resolve(undefined)

      showModal(MODAL_ID.web3.approveWithdrawal, {
        withdrawalIntent,
        onApproved: handleApproved,
        onCancel: handleCancel
      })
    })
  }

  const withdrawFunds = async (priorityRate: number) => {
    const { address: to, amount } = withdrawal
    const { address: from, display_address } = wallet
    const organizationId = wallet.tk_suborg_id
    const avatarUrl = user?.avatar_media?.size0_url

    const txnAuthBundle = await approveWithdrawal(
      amount,
      { address: display_address, avatarUrl },
      { address: getDisplayAddress(to) }
    )

    if (!txnAuthBundle) return

    const signer = await getTransactionSigner(organizationId, txnAuthBundle)
    const { blockhash } = await getSolanaRecentBlockhash()
    const source = getUsdcAccount(from)
    const destination = getUsdcAccount(to)

    const { exists } = await checkSolanaAccountExists({
      address: destination.tokenAddress.toString()
    })

    const withdrawalTxn = buildWithdrawal(
      source,
      { ...destination, exists },
      blockhash,
      amount,
      withdrawalFee,
      priorityRate
    )

    await signer.addSignature(withdrawalTxn, from)

    const encodedTransaction = encodeTransaction(withdrawalTxn)

    const { txnHash } = await submitWeb3Withdrawal({
      transaction: encodedTransaction
    })

    return txnHash
  }

  // for optimistic update of balance if needed. Eric, Thu Nov 14 2024
  // const updateBalance = (expectedBalance: number) => {
  //   const queryData =
  //     queryClient.getQueryData<WalletQueryResponse>(walletQueryKey)
  //
  //   const newQueryData = { ...queryData, balance: expectedBalance }
  //
  //   queryClient.setQueryData<WalletQueryResponse>(walletQueryKey, newQueryData)
  // }

  const submitWithdrawal = async (mfaCode?: string) => {
    const { solana_priority_rate } = await startWithdrawal(mfaCode)

    const txnHash = await withdrawFunds(solana_priority_rate)
    if (!txnHash) return false

    await finishWithdrawal(txnHash)
    return true
  }

  const transitionTo = <T extends WithdrawalTransition>(transition: T) => {
    const { sequence } = transition
    sequence.goTo(transition.step)
  }

  const setAddress = (address: string) =>
    dispatch({ type: 'SET_ADDRESS', payload: { address } })

  const setAmount = (amount: number) =>
    dispatch({ type: 'SET_AMOUNT', payload: { amount } })

  const minimum = useMemo(() => {
    const value = withdrawalFee + MIN_WITHDRAW_AMOUNT
    return {
      value,
      formatted: formatCurrency(value, false)
    }
  }, [withdrawalFee])

  const maximum = useMemo(
    () => ({
      value: balance,
      formatted: formatCurrency(balance, false)
    }),
    [balance]
  )

  const value = {
    balance,
    withdrawalFee,
    withdrawal,
    transitionTo,
    setAddress,
    setAmount,
    minimum,
    maximum,
    submitWithdrawal
  }

  return (
    <WithdrawFundsContext.Provider value={value}>
      {children}
    </WithdrawFundsContext.Provider>
  )
}
