import classNames from 'classnames'
import Icon from '../Icon'
import styles from './styles.module.scss'

type Props = {
  icon: string
  className?: string
  size?: number
  iconSize?: number
}

const DEFAULT_SIZE = 72
const DEFAULT_ICON_SIZE = 38

const SolanaSpinner = ({
  icon,
  className,
  size = DEFAULT_SIZE,
  iconSize = DEFAULT_ICON_SIZE
}: Props) => {
  return (
    <div
      className={classNames(styles.container, className)}
      style={{ width: size, height: size }}
    >
      <Icon
        name={icon}
        className={classNames(
          'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-10'
        )}
        style={{ width: iconSize, height: iconSize }}
      />
      <Icon
        name="solanaSpinner"
        className={styles.spinner}
        style={{ width: size, height: size }}
      />
    </div>
  )
}

export default SolanaSpinner
