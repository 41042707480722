import { z } from 'zod'
import { successResponseSchema } from '@/api/resources/shared/success'

export const solanaAccountExistsInputSchema = z.object({
  address: z.string()
})

export const solanaAccountExistsResponseSchema = successResponseSchema.extend({
  address: z.string(),
  exists: z.boolean()
})

export type SolanaAccountExistsInput = z.infer<
  typeof solanaAccountExistsInputSchema
>

export type SolanaAccountExistsResponse = z.infer<
  typeof solanaAccountExistsResponseSchema
>
