import React from 'react'
import { BadgeProps } from '../Badge'
import InActiveBadge from '../Badge/InActiveBadge'
import ForSaleBadge from '../Badge/ForSaleBadge'

type Props = {
  size: BadgeProps['size']
  isInactive: boolean
}

export default function MarketplaceBadges({ size, isInactive }: Props) {
  return isInactive ? (
    <InActiveBadge size={size} />
  ) : (
    <ForSaleBadge size={size} />
  )
}
