import { CryptoFees } from '@/api/resources/crypto/withdraw/fees/schema'
import { CRYPTO_NETWORK_SELECTOR_OPTIONS } from '../../core/constants'

export const parseAvailableWithdrawalNetworks = (
  networkFees: CryptoFees,
  balance: number
) => {
  const options = Object.values(CRYPTO_NETWORK_SELECTOR_OPTIONS)
  const filteredOptions = options
    .filter(option => networkFees[option.value] !== undefined)
    .map(option => {
      const fee = networkFees[option.value]
      const transformedFee = Math.round((fee + 0.01) * 1e12) / 1e12
      const disabled = transformedFee > balance
      if (!disabled) return option
      return {
        ...option,
        disabled,
        info: `At least ${transformedFee} USDC is required to use this network.`
      }
    })
  return filteredOptions
}
