import React from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from '../../../ModalSequence'
import Button from '@/components/Button'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ModalChildrenWrappers } from '../../../BaseModal'
import { useWallet } from '@/features/wallet/providers/wallet'
import { notifyWalletError } from '@/features/wallet/utils/notify'

export const createPasskey = (
  sequenceProps: IModalSequenceControllerProps
): IModalSequenceViewProps => {
  return {
    id: 'createPasskey',
    title: 'Create a Passkey',
    overlayHeaderOpaqueOnScroll: true,
    header: {
      image: {
        path: 'pages/wallet/migration/',
        id: 'create-passkey',
        alt: 'header image',
        width: 480,
        height: 300
      }
    },
    view: viewProps => <CreatePasskeyForm {...viewProps} {...sequenceProps} />
  }
}

// @todo - make a type for modal forms with the body footer and sequence props
interface CreatePasskeyForm
  extends Omit<ModalChildrenWrappers, 'ModalHeaderMedia'>,
    IModalSequenceControllerProps {}

const CreatePasskeyForm = ({
  ModalBody,
  ModalFooter,
  next
}: CreatePasskeyForm) => {
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = useForm()

  const { registerWallet } = useWallet()

  const onSubmit: SubmitHandler<{}> = async () => {
    try {
      await registerWallet()
      next()
    } catch (err) {
      notifyWalletError(err)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <p className="text-gray-300">
          To connect your wallet, we first need to set up a passkey, which will
          serve as your authentication method when managing funds in your Droppp
          Wallet.
          <br />
          <br />A passkey is a more secure alternative to a traditional
          password, generated and stored securely by your device. It's typically
          accessed through on-device biometrics like a face or fingerprint scan,
          or through other device-based authentication methods, such as a PIN
          code.
          <br />
          <br />
          We recommend creating a passkey using password synchronization tools
          like{' '}
          <a
            href="https://passwords.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-link"
          >
            Google Password Manager
          </a>{' '}
          or{' '}
          <a
            href="https://support.apple.com/en-us/109016"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-link"
          >
            iCloud Keychain
          </a>
          .
        </p>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" loading={isSubmitting} className="w-full">
          Create Passkey
        </Button>
      </ModalFooter>
    </form>
  )
}
