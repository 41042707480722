import { useRouter } from 'next/router'
import * as paths from '../routing/paths'
import { VerifyType } from '@/components/Modals/ModalV2/content/verifyIdentity'
import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { useWallet } from '@/features/wallet/providers/wallet'

export type GoToType = {
  signIn: () => void
  addFunds: () => void
  verifyIdentity: (introType: VerifyType) => void
  reconnectWallet: () => void
  createWallet: () => void
  migrateWallet: () => void
  marketplaceFAQ: () => void
  inventory: () => void
  account: () => void
}

export type UseNavigationResult = {
  goTo: GoToType
}

export const useNavigation = (): UseNavigationResult => {
  const router = useRouter()
  const { verifyIdPrompted } = useWallet()

  const signIn = () => router.push(paths.login(router.asPath)).then()
  const addFunds = () => router.push(`/wallet`).then()
  const marketplaceFAQ = () => router.push('/faq#marketplace')
  const inventory = () => router.push(paths.inventory)
  const account = () => router.push(paths.account).then()

  const reconnectWallet = () =>
    showModal(MODAL_ID.wallet.reconnect, { overlayClassName: 'z-viewModal' })

  const createWallet = () =>
    showModal(MODAL_ID.wallet.create.sequence, {
      overlayClassName: 'z-viewModal'
    })

  const migrateWallet = () => router.push(`/wallet/migrate`).then()

  const verifyIdentity = (introType: VerifyType) => {
    if (verifyIdPrompted) {
      showModal(MODAL_ID.identityVerificationGate, {
        introType
      })
    } else {
      addFunds().then()
    }
  }

  return {
    goTo: {
      signIn,
      addFunds,
      verifyIdentity,
      reconnectWallet,
      createWallet,
      migrateWallet,
      marketplaceFAQ,
      inventory,
      account
    }
  }
}
