import { ActionSection } from '@/components/ActionSection'
import { SUPPORT_EMAIL } from '@/config'
import React, { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {}

export default function RemoveWalletSection({}: Props) {
  return (
    <ActionSection
      sectionTitle="Remove Wallet"
      primaryAction={{
        label: 'Contact',
        action: () =>
          (location.href = `mailto:${SUPPORT_EMAIL}?subject=Remove Droppp Wallet&body=Please remove my Droppp wallet from my account.`)
      }}
    >
      If your Droppp Wallet address is compromised or you need a different one,
      please contact Droppp Support for assistance.
    </ActionSection>
  )
}
