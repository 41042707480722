import React, { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { MODAL_ID } from '@/constants/modalId'
import InfoModal from './InfoModal'
import InputCustomAddress from '@/components/Inputs/InputCustomAddress'
import { Controller, useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import Button from '@/components/Button'
import { CLOUDINARY_WALLET_PATH } from '@/pages/wallet'
import { setReturnUri } from '@/util/cookieHelpers'
import BooleanModal from './BooleanModal'
import { SUPPORT_EMAIL } from '@/config'
import Link from 'next/link'

type PresentingContext = 'wallet' | 'profile'
interface Props {
  presentingContext: PresentingContext
  isAddressTypeNone: Boolean
}

type ContentDisplay = 'setupProfile' | 'setupWallet' | 'upgradeToCustom'

const title = (contentDisplay: ContentDisplay): ReactNode => {
  switch (contentDisplay) {
    case 'setupProfile':
      return "Let's set up your profile"
    case 'setupWallet':
      return 'Create your Droppp Address'
    case 'upgradeToCustom':
      return 'Make your profile sing!'
  }
}

const body = (contentDisplay: ContentDisplay): ReactNode => {
  switch (contentDisplay) {
    case 'setupProfile':
      return (
        <>
          Since you’re new, you don’t have a profile yet. To get started, you’ll
          need to set up a Droppp Address. You can get an address by either
          upgrading to a custom Droppp Address now for $4.99, or get a free
          random Droppp Address by making your first purchase during a drop or
          setting up your{' '}
          <Link href="/wallet" className="inline-link">
            Droppp Wallet
          </Link>
          , which is used to make purchases on Droppp Marketplace.
        </>
      )
    case 'setupWallet':
      return (
        <>
          Since you’re new, you don't have a Droppp Address yet. You can get an
          address by either upgrading to a custom Droppp Address now for $4.99,
          or get a free random Droppp Address by making your first purchase
          during a drop or setting up your{' '}
          <Link href="/wallet" className="inline-link">
            Droppp Wallet
          </Link>
          , which is used to make purchases on Droppp Marketplace.
        </>
      )
    case 'upgradeToCustom':
      return (
        <>
          Stand out with a unique and easy to remember Droppp Address for a
          one-time purchase of $4.99.
        </>
      )
  }
}

const CustomWaxAddressModal = NiceModal.create(
  ({ presentingContext, isAddressTypeNone }: Props) => {
    const FreeAddressModal = useModal(BooleanModal)
    const contentDisplay = isAddressTypeNone
      ? presentingContext == 'wallet'
        ? 'setupWallet'
        : 'setupProfile'
      : 'upgradeToCustom'

    return (
      <InfoModal
        id={MODAL_ID.wallet.customWaxAddress}
        title={title(contentDisplay)}
        header={{
          image: {
            path: CLOUDINARY_WALLET_PATH,
            id: 'upgrade-address',
            alt: 'Monster singing into mic',
            width: 250,
            height: 245
          }
        }}
        subFooter={
          <div className="mt-3 text-center">
            <Button
              className="underline text-gray-300 body-sm"
              theme="clean"
              onClick={() =>
                FreeAddressModal.show({
                  title: 'Need a free Address?',
                  children: (
                    <>
                      <p>
                        We are more than happy to provide our users with a free
                        wallet address on a case-by-case basis. A typical
                        situation where you need a wallet address before
                        participating in your first drop is when you have bought
                        packs or tokens on the secondary market and need to
                        transfer them to your Droppp account to unpack or redeem
                        them.
                        <br />
                        <br />
                        Please allow 2-3 business days for your request to be
                        processed. However if you need an address immediately,
                        you can always purchase a custom address for $4.99.
                      </p>
                    </>
                  ),
                  primaryButton: {
                    label: 'Drop Us a Line',
                    onClick: () =>
                      (window.location.href = `mailto:${SUPPORT_EMAIL}&subject=Send me a free address!`)
                  }
                })
              }
            >
              Need a Free Address Sooner?
            </Button>
          </div>
        }
      >
        {({ ModalHeaderMedia, ...modalProps }) => {
          return (
            <>
              <ModalHeaderMedia
                content={{
                  image: {
                    path: CLOUDINARY_WALLET_PATH,
                    id: 'upgrade-address',
                    alt: 'Monster singing into mic',
                    width: 250,
                    height: 245
                  }
                }}
              />
              <CustomAddressForm
                contentDisplay={contentDisplay}
                {...modalProps}
              />
            </>
          )
        }}
      </InfoModal>
    )
  }
)

const CustomAddressForm = ({ ModalBody, ModalFooter, contentDisplay }) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid }
  } = useForm({
    defaultValues: { customAddress: '' }
  })

  const router = useRouter()

  const hideModal = () => NiceModal.hide(MODAL_ID.wallet.customWaxAddress)

  const onSubmit = data => {
    if (!isValid) return
    setReturnUri('/wallet')
    router.push(`/purchase-wax?address=${data.customAddress}`)
    hideModal()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <p className="mb-3 text-gray-300">{body(contentDisplay)}</p>
        <Controller
          control={control}
          name={'customAddress'}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <div className="utility text-white mb-1">New Address</div>
              <InputCustomAddress
                onChange={address => field.onChange(address)}
                onChangeValidation={isValid => {
                  !isValid &&
                    setError('customAddress', {
                      message: 'Please enter a valid address'
                    })
                }}
              />
            </>
          )}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          theme="rainbow"
          className="w-full"
          disabled={!isValid}
        >
          Continue to purchase
        </Button>
      </ModalFooter>
    </form>
  )
}

export default CustomWaxAddressModal
