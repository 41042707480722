import { useTurnkey } from '@turnkey/sdk-react'
import { useQueryClient } from '@tanstack/react-query'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect
} from 'react'
import { useAuth } from '@/contexts/auth'
import useLocalStorage from '@/storage/useLocalStorage'
import { StorageKey } from '@/storage/keys'
import { useWalletQuery, walletQueryKey } from '@/api/resources/user/wallet/get'
import IdentityVerificationState from '@/types/identityVerificationState'
import {
  ConnectedWallet,
  ExportWalletError,
  InvalidPasskeyError,
  InvalidRecoveryCodeError,
  PasskeyCreationError,
  UpdateRecoveryEmailError,
  WalletContextType
} from '@/features/wallet/types'
import {
  loginReadWriteSession,
  findSessionCredentialId,
  finishUserRecovery,
  getReadWriteSession,
  performRegister,
  resolveAccountWallet,
  startUserRecovery,
  exportUserWallet,
  updateUserEmail
} from '@/features/wallet/utils/turnkey'
import { useHasMounted } from '@/hooks/useHasMounted'
import { useRouter } from 'next/router'
import { logSessionEvent } from '@/util/logger'
import { notifyWalletError } from '@/features/wallet/utils/notify'
import { setWeb3Wallet } from '@/api/resources/user/web3/set'

const WalletContext = createContext<Partial<WalletContextType>>({})
export const useWallet = () => useContext(WalletContext)

export const WalletProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const hasMounted = useHasMounted()
  const { user, isAuthenticated, hasVerifiedSession, isUserLoading } = useAuth()
  const { passkeyClient, turnkey } = useTurnkey()
  const queryClient = useQueryClient()

  const isWalletQueryEnabled = hasVerifiedSession
  const refetchOnWindowFocus = router.pathname.startsWith('/wallet')

  const walletQuery = useWalletQuery({
    enabled: isWalletQueryEnabled,
    refetchOnWindowFocus,
    onError: (err: unknown) => {
      // If `isWalletQueryEnabled` is true this shouldn't fail, but sometimes
      // it does (in my testing). Routing through wallet error handling so that we can
      // get a better idea of what's going on. Eric, Thu Dec 5 2024
      notifyWalletError(err)
    }
  })

  const isInitialUserLoading = isAuthenticated && isUserLoading && !user

  const isWalletLoading =
    !hasMounted || isInitialUserLoading || walletQuery.isInitialLoading

  logSessionEvent(
    `[wallet] isWalletQueryEnabled: ${isWalletQueryEnabled}, refetchOnWindowFocus: ${refetchOnWindowFocus}, isWalletLoading: ${isWalletLoading}`
  )

  const walletData = walletQuery.data
  const web3Wallet = walletData?.web3data

  const hasDeposited = walletData?.has_deposited
  const verifyIdPrompted = walletData?.verify_id_prompted
  const balance = walletData?.balance
  const withdrawalFee = walletData?.withdraw_fees?.SOL
  const migrationNeeded = walletData?.migration_needed
  const migrationStep = walletData?.migration_step
  const identityVerification =
    walletData?.identityVerificationState ?? IdentityVerificationState.New

  const isKycVerified =
    identityVerification === IdentityVerificationState.Completed

  const [connectedWallet, setConnectedWallet] =
    useLocalStorage<ConnectedWallet | null>(StorageKey.Wallet)

  logSessionEvent(
    `[wallet] connectedWallet: ${JSON.stringify(connectedWallet || {})}`
  )

  const accountWallet = resolveAccountWallet(web3Wallet, connectedWallet)

  logSessionEvent(
    `[wallet] accountWallet: ${JSON.stringify(accountWallet || {})}`
  )

  const saveConnectedWallet = (subOrgId: string, credentialId: string) => {
    const connected_at = new Date().toISOString()
    setConnectedWallet({
      tk_suborg_id: subOrgId,
      tk_credential_id: credentialId,
      connected_at,
      auto_approve: true
    })
  }

  const registerWallet = async () => {
    if (!user) return

    try {
      const { registeredWallet, passkey } = await performRegister(
        user,
        passkeyClient
      )

      const { tk_suborg_id } = registeredWallet
      saveConnectedWallet(tk_suborg_id, passkey.attestation.credentialId)
      await queryClient.invalidateQueries(walletQueryKey)
    } catch (error) {
      throw new PasskeyCreationError(error)
    }
  }

  const reconnectWallet = async () => {
    if (!user) return

    try {
      const organizationId = accountWallet?.wallet?.tk_suborg_id
      const session = await passkeyClient.login({ organizationId })

      const credentialId = findSessionCredentialId(session)
      saveConnectedWallet(organizationId, credentialId)
    } catch (error) {
      throw new InvalidPasskeyError(error)
    }
  }

  const startWalletRecovery = async () => {
    if (!user) return
    await startUserRecovery()
  }

  const finishWalletRecovery = async (credentialBundle: string) => {
    if (!accountWallet?.wallet) return
    if (!user) return

    try {
      const passkey = await finishUserRecovery(
        credentialBundle,
        accountWallet.wallet,
        user,
        passkeyClient
      )

      const organizationId = accountWallet?.wallet?.tk_suborg_id

      setConnectedWallet({
        tk_suborg_id: organizationId,
        tk_credential_id: passkey.attestation.credentialId
      })
    } catch (error) {
      throw new InvalidRecoveryCodeError(error)
    }
  }

  const notEnoughFunds = (price: number) => price > balance

  const removeConnectedWallet = () => {
    setConnectedWallet(null)
    localStorage.removeItem(StorageKey.Wallet)
  }

  const logoutWallet = () => {
    removeConnectedWallet()
    turnkey.logoutUser().then()
  }

  const getCredentials = async (): Promise<string> => {
    const readWriteSession = await getReadWriteSession(turnkey)
    return readWriteSession?.credentialBundle
  }

  const resolveCredentials = async (): Promise<string> => {
    let credentialBundle = await getCredentials()

    if (!credentialBundle) {
      const organizationId = accountWallet?.wallet?.tk_suborg_id

      try {
        credentialBundle = await loginReadWriteSession(
          organizationId,
          passkeyClient
        )
      } catch (error) {
        throw new InvalidPasskeyError(error)
      }
    }

    return credentialBundle
  }

  const isAutoApproveEnabled = !!connectedWallet
    ? connectedWallet.auto_approve
    : true

  const setAutoApproveEnabled = (enabled: boolean) =>
    setConnectedWallet({ ...connectedWallet, ...{ auto_approve: enabled } })

  const exportWallet = async () => {
    try {
      const walletId = accountWallet?.wallet?.tk_wallet_id
      const organizationId = accountWallet?.wallet?.tk_suborg_id
      return await exportUserWallet(walletId, organizationId, passkeyClient)
    } catch (error) {
      throw new ExportWalletError(error)
    }
  }

  const updateRecoveryEmail = async (email: string) => {
    try {
      const userId = accountWallet?.wallet?.tk_user_id
      const organizationId = accountWallet?.wallet?.tk_suborg_id
      await updateUserEmail(userId, organizationId, email, passkeyClient)
      await setWeb3Wallet({
        tk_user_id: userId,
        tk_suborg_id: organizationId,
        tk_email: email
      })
      await queryClient.invalidateQueries(walletQueryKey)
    } catch (error) {
      throw new UpdateRecoveryEmailError(error)
    }
  }

  useEffect(() => {
    const validateTurnkeySession = async () => {
      const tkUser = await turnkey.getCurrentUser()
      const tkUserId = accountWallet?.wallet?.tk_user_id
      const doesNotMatch = tkUser && tkUserId && tkUser.userId !== tkUserId
      if (doesNotMatch) await turnkey.logoutUser()
    }

    if (accountWallet && turnkey) validateTurnkeySession().then()
  }, [accountWallet, turnkey])

  const value = {
    isWalletLoading,
    isNotSignedIn: !isAuthenticated,
    isKycVerified,
    isNotKycVerified: !isKycVerified,
    notEnoughFunds,
    balance,
    withdrawalFee,
    identityVerification,
    verifyIdPrompted,
    hasDeposited,
    migrationNeeded,
    migrationStep,
    registerWallet,
    reconnectWallet,
    exportWallet,
    updateRecoveryEmail,
    startWalletRecovery,
    finishWalletRecovery,
    passkeyClient,
    logoutWallet,
    getCredentials,
    resolveCredentials,
    isAutoApproveEnabled,
    setAutoApproveEnabled,
    ...accountWallet
  }

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  )
}
