import classNames from 'classnames'
import React from 'react'
import Badge, { BadgeProps } from '.'
import { iconClass } from './IconClasses'

interface Props {
  size?: BadgeProps['size']
}

export default function ForSaleBadge({ size = 'default' }: Props) {
  return (
    <Badge
      size={size}
      icon="forSale"
      hideLabel={size == 'fluid-sm'}
      label={size == 'fluid-sm' ? '' : 'For Sale'}
      iconClass={iconClass(size)}
      className={classNames('border-green', {
        '!px-[.4em]': size == 'fluid-sm'
      })}
    />
  )
}
