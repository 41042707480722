import PoweredBySolana from '@/components/PoweredBySolana'

type Props = {
  title: string
}

const PoweredBySolanaRightSlotContent = ({ title }: Props) => {
  return (
    <div className="flex flex-col gap-half items-end justify-end">
      <div className="h8 font-black">{title}</div>
      <PoweredBySolana
        className="flex gap-[3px] items-center !text-xxxs text-gray-300"
        icon="solanaWordmarkWhite"
        iconClassName="w-[42px] h-[6px]"
      />
    </div>
  )
}

export default PoweredBySolanaRightSlotContent
