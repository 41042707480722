import { ReactNode } from 'react'
import { UserStatus } from '@/features/wallet/types'
import { NextRouter } from 'next/router'
import { hideModal, showModal, switchModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { NoWalletImage } from './NoWalletImage'
import { MigrationBodyComponent } from './MigrationBody'
import { useNavigation } from '@/hooks/useNavigation'

export const Content = (
  router: NextRouter,
  balance?: number
): Record<
  UserStatus,
  {
    title: string
    description: string
    bodyComponent?: ReactNode
    primaryButton: {
      label: string
      action?: () => void
      disabled?: boolean
    }
    secondaryButton?: {
      label: string
      action: () => void
    }
  }
> => {
  const { goTo } = useNavigation()
  let migrationSecondaryButton = undefined

  if (balance) {
    migrationSecondaryButton = {
      label: 'Withdraw Funds',
      action: () => showModal(MODAL_ID.wallet.withdrawFunds)
    }
  }

  const migrationPrimaryButton = (label = 'Migrate Wallet') => ({
    label,
    action: () => router.push('/wallet/migrate')
  })

  const noWalletSecondaryButton = {
    label: 'Learn About Droppp Wallet',
    action: () => showModal(MODAL_ID.wallet.newWalletAlert)
  }

  return {
    unverified: {
      title: 'Introducing Droppp Wallet',
      description:
        'Before we can create your Droppp Wallet, please verify your identity to get started.',
      bodyComponent: <NoWalletImage />,
      primaryButton: {
        label: 'Create Wallet',
        disabled: true
      },
      secondaryButton: noWalletSecondaryButton
    },
    verifiedNoWallet: {
      title: 'Introducing Droppp Wallet',
      description:
        'Droppp has transitioned to a new wallet infrastructure, giving you full control of the security of your wallet. Follow this guide to create your new Droppp Wallet.',
      bodyComponent: <NoWalletImage />,
      primaryButton: {
        label: 'Create Wallet',
        action: () =>
          showModal(MODAL_ID.wallet.create.sequence, {
            onComplete: () =>
              switchModal(
                MODAL_ID.wallet.create.sequence,
                MODAL_ID.wallet.create.success,
                {
                  title: 'Wallet Creation Finished',
                  description:
                    'Your wallet is now set up and connected to Droppp! To purchase items on the Droppp Marketplace, head to the Wallet page to add funds. If you’re ready to list items, you can do that directly from your inventory.',
                  primaryButton: {
                    label: 'Explore Wallet',
                    onClick: () => hideModal(MODAL_ID.wallet.create.success)
                  },
                  secondaryButton: {
                    label: 'Inventory',
                    onClick: () => goTo.inventory(),
                    theme: 'secondary'
                  }
                }
              )
          })
      },
      secondaryButton: noWalletSecondaryButton
    },
    canMigrate: {
      title: 'Migrate to new Droppp Wallet',
      description:
        'Your funds are safe, but your listings and Droppp Balance are inactive until you migrate to the new Droppp Wallet. Alternatively, you can withdraw your funds to an exchange or external wallet before migrating.',
      bodyComponent: <MigrationBodyComponent />,
      primaryButton: migrationPrimaryButton(),
      secondaryButton: migrationSecondaryButton
    },
    startedMigration: {
      title: 'Resume Wallet Migration',
      description:
        'Complete your wallet migration to activate your listings, unlock your funds, and shop on Droppp Marketplace.',
      bodyComponent: <MigrationBodyComponent />,
      primaryButton: migrationPrimaryButton('Resume Migration'),
      secondaryButton: migrationSecondaryButton
    }
  }
}
