import React from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from '../../../ModalSequence'
import { ModalFooterButtons } from '../../../ModalFooterButtons'
import RevealSeedPhrase from '@/features/wallet/components/RevealSeedPhrase'

export const seedPhrase = (
  sequenceProps: IModalSequenceControllerProps,
  onComplete: () => void
): IModalSequenceViewProps => {
  return {
    id: 'seedPhrase',
    title: 'Seed Phrase',
    overlayHeaderOpaqueOnScroll: false,
    view: ({ ModalBody, ModalFooter }) => {
      return (
        <>
          <ModalBody>
            <RevealSeedPhrase />
            <p className="mt-2 body-sm text-gray-300">
              Reminder: As long as your wallet is connected, you can view your
              seed phrase anytime in the Droppp Wallet management settings.
            </p>
          </ModalBody>
          <ModalFooter>
            <ModalFooterButtons
              primaryButton={{
                onClick: onComplete,
                label: 'Finish'
              }}
            />
          </ModalFooter>
        </>
      )
    }
  }
}
