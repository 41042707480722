import React from 'react'
import { useForm } from 'react-hook-form'
import { useDepositFunds } from '@/features/wallet/modals/deposit/DepositFundsProvider'
import {
  DepositContextType,
  DepositSequence,
  DepositStepConfiguration,
  DepositStepProps,
  DepositSteps
} from '@/features/wallet/modals/deposit/types'
import {
  footer,
  paragraphClasses,
  submit
} from '@/components/Modals/ModalV2/shared/props'
import { Button } from '@/components'
import classNames from 'classnames'
import { ControlledConfirmation } from '@/components/AcknowledgeBox/Confirmation'
import { DEPOSIT_CLOUDINARY_PATH } from '@/features/walletLegacy/core/constants'
import { cssFormattedSpacing } from '@/util/tailwindHelpers'
import { TransferWarning } from '@/features/wallet/components/TransferWarning'
import { tryApiAction } from '@/api/core/compat'
import { getWalletAddress } from '@/api/resources/user/wallet/address/get'
import CoinbaseOverlayDisclaimer from '@/features/wallet/components/CoinbaseOverlayDisclaimer'
import { HeaderItem } from '@/features/wallet/components/HeaderItem'
import InlineNetworkIcon from '@/features/wallet/components/InlineNetworkIcon'

type ConfirmSolanaForm = {
  confirmedSolana: boolean
}

const SolanaConfirmStep = ({
  ModalBody,
  ModalFooter,
  sequence
}: DepositStepProps) => {
  const { transitionTo, deposit, updateDeposit } = useDepositFunds()

  const { confirmedSolana } = deposit

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm<ConfirmSolanaForm>({ defaultValues: { confirmedSolana } })

  const submitForm = handleSubmit(async formData => {
    const { success, data } = await tryApiAction(() =>
      getWalletAddress({ chain: 'SOL' })
    )

    if (success) {
      const { image, details } = data
      const depositOptionValues = { barcode: image, address: details?.address }

      updateDeposit({ ...formData, depositOptionValues })
      transitionTo({
        step: 'solanaDepositStep',
        sequence
      })
    }
  })

  return (
    <form onSubmit={submitForm}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <p className={classNames(paragraphClasses)}>
            Next, please confirm that you are sending USDC on the Solana
            Network.
          </p>
          <div
            style={{
              '--containerXPadding': cssFormattedSpacing(3),
              '--containerYPadding': cssFormattedSpacing(2)
            }}
            className={'border border-gray-700 rounded-2xl'}
          >
            <div className="grid grid-cols-2 py-[var(--containerYPadding)] border-b border-inherit">
              <HeaderItem category="Network" label="Solana" icon="solana" />
            </div>

            <div className="py-[var(--containerYPadding)] px-[var(--containerXPadding)]">
              <TransferWarning />
            </div>
          </div>
          <ControlledConfirmation
            className="bg-gray-800"
            name="confirmedSolana"
            control={control}
            required
          >
            <span className="leading-[22px]">
              I acknowledge that I’m sending{' '}
              <InlineNetworkIcon title="USDC" icon="usdc" /> over the{' '}
              <InlineNetworkIcon title="Solana" icon="solana" /> network.
            </span>
          </ControlledConfirmation>
        </div>
      </ModalBody>
      <ModalFooter {...footer}>
        <Button
          disabled={!isValid}
          loading={isSubmitting}
          {...submit}
          type="submit"
        >
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

SolanaConfirmStep.configure = (
  context: DepositContextType,
  sequence: DepositSequence
): DepositStepConfiguration => {
  const { transitionTo } = context

  const handleRetreatClick = () =>
    transitionTo({
      step: 'confirmUsdcStep',
      sequence
    })

  return {
    id: DepositSteps.solanaConfirmStep,
    title: 'Choose the Solana Network',
    overlayHeaderOpaqueOnScroll: true,
    onRetreat: handleRetreatClick,
    header: {
      overlayComponent: <CoinbaseOverlayDisclaimer />,
      className: 'bg-gradient-to-b from-gray-850/0 to-black aspect-[9/5]',
      video: {
        path: DEPOSIT_CLOUDINARY_PATH,
        id: 'tutorial-solana-usdc'
      }
    },
    view: props => (
      <SolanaConfirmStep sequence={sequence} {...props}></SolanaConfirmStep>
    )
  }
}

export { SolanaConfirmStep }
