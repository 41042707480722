import { z } from 'zod'

export const listingBalanceSchema = z.object({
  balance: z.number(),
  balance_cached: z.boolean(),
  balance_updated: z.string(),
  associated_address: z.string(),
  address_cached: z.boolean()
})

export const listingPurchaseSchema = z.object({
  id: z.number(),
  chain_asset_id: z.number(),
  seller_account_id: z.number().nullable().optional(),
  buyer_account_id: z.number().nullable().optional(),
  listing_price: z.number(),
  net_amount: z.number().optional(),
  net_fee: z.number().optional(),
  collection_fee: z.number(),
  blockchain_fee: z.number(),
  marketplace_fee: z.number(),
  drop_tx_id: z.number().nullable().optional(),
  time_created: z.string().optional(),
  time_updated: z.string().optional(),
  time_reserve: z.string().optional(),
  time_purchased: z.string().nullable(),
  timeout: z.number(),
  buyer_wallet_history_id: z.number().nullable().optional(),
  seller_wallet_history_id: z.number().nullable().optional(),
  status: z.number(),
  seller_user_id: z.number(),
  buyer_user_id: z.number().optional(),
  reservation_id: z.string()
})

export type ListingBalance = z.infer<typeof listingBalanceSchema>
export type ListingPurchase = z.infer<typeof listingPurchaseSchema>
