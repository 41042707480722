import { classNames } from '@/util/tailwindHelpers'
import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { useWallet } from '../providers/wallet'
import Button from '@/components/Button'
import Icon from '@/components/Icon'

interface Props {
  size?: 'sm' | 'lg'
}

export const WalletActions = (props: Props) => {
  const { balance } = useWallet()
  const { size } = props

  const handleWithdrawFundsClick = () => showModal(MODAL_ID.web3.withdrawal)

  const handleDepositFundsClick = () => showModal(MODAL_ID.web3.deposit)

  const handleManageWalletClick = async () => showModal(MODAL_ID.wallet.manage)

  const canWithdraw = balance > 0

  return (
    <div
      className={classNames('flex gap-2', {
        'gap-1': size === 'sm'
      })}
    >
      <WalletAction
        {...props}
        label="Manage"
        onClick={handleManageWalletClick}
        icon="wallet"
      />
      {canWithdraw && (
        <WalletAction
          {...props}
          label="Withdraw"
          onClick={handleWithdrawFundsClick}
          icon="withdraw"
        />
      )}
      <WalletAction
        {...props}
        label="Add Funds"
        onClick={handleDepositFundsClick}
        icon="plus"
      />
    </div>
  )
}

interface WalletActionProps {
  onClick: () => void
  label: string
  icon: string
  size?: 'sm' | 'lg'
}

const WalletAction = ({ onClick, label, icon, size }: WalletActionProps) => {
  const iconSize = size === 'sm' ? 'w-[18px] h-[18px]' : 'w-3 h-3'
  const buttonClasses =
    size === 'sm' ? 'h-[34px] rounded-xl mb-[2px]' : 'h-6 rounded-2xl mb-1'
  return (
    <Button
      className="group flex-1 hover:scale-105 transition-transform duration-button"
      theme="clean"
      onClick={onClick}
    >
      <div
        className={classNames(
          'bg-white/15 h-6 flex items-center justify-center transform-gpu',
          buttonClasses
        )}
      >
        <Icon name={icon} className={iconSize} />
      </div>
      <span className="body-xs text-gray-300 group-hover:text-white transition-colors duration-button">
        {label}
      </span>
    </Button>
  )
}
