import { z } from 'zod'
import { successResponseSchema } from '@/api/resources/shared/success'

export const web3WithdrawalInputSchema = z.object({
  transaction: z.string()
})

export const web3WithdrawalResponseSchema = successResponseSchema.extend({
  txnHash: z.string()
})

export type Web3WithdrawalInput = z.infer<typeof web3WithdrawalInputSchema>
export type Web3WithdrawalResponse = z.infer<
  typeof web3WithdrawalResponseSchema
>
