import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { MODAL_ID } from '@/constants/modalId'
import TimeoutCounter from '@/components/TimeoutCounter'
import ViewModal from '@/components/Modals/ModalV2/ViewModal'
import InventoryBuyItem from '@/components/ProductBuyItem'
import Loading from '@/components/Loading'
import ModalStateGraphic from '@/components/Modals/ModalV2/ModalStateGraphic'
import { useAuth } from '@/contexts/auth'
import { ReservedListing } from '@/api/resources/shared/listingReserve'
import { useReservation } from './useReservation'
import { hideModal } from '@/components/Modals/ModalV2'
import { Toast } from '@/components'
import { useNavigation } from '@/hooks/useNavigation'

export type ReservationTimeout = {
  label: string
  message: string
  onRenewalError: (err?: unknown) => void
}

export type PurchaseSuccess = {
  continueTo: string
  onContinue?: () => void
  onDone?: () => void
}

export interface ProductBuyModalProps {
  initialReservation: ReservedListing
  preReservePrice: number
  options?: {
    timeout?: ReservationTimeout
    success?: PurchaseSuccess
  }
}

const notifyPurchaseSuccess = (callback: () => void) => {
  Toast({
    type: 'success',
    autoClose: 5000,
    description:
      'This item is pending payment confirmation but will appear in your inventory and collection tracker in the meantime. If the payment fails, the item will be returned to the seller.',
    action: {
      label: 'VIEW INVENTORY',
      callback
    }
  })
}

export const ProductBuyModal = NiceModal.create(
  ({ initialReservation, preReservePrice, options }: ProductBuyModalProps) => {
    const { timeout, success } = options ?? {}

    const { goTo } = useNavigation()

    const { isUserLoading: isLoadingUser, walletBalance, fetchUser } = useAuth()

    const { isRenewing, isRenewError, didPurchase, reservation, actions } =
      useReservation({
        initialReservation,
        onRenewalError: timeout?.onRenewalError
      })

    const handleTimeout = () => {
      hideModal(MODAL_ID.web3.approvePurchase)
      NiceModal.show(MODAL_ID.boolean, timeoutModal).then()
    }

    const handleRetry = () => actions.renew()

    const handleSuccess = () => {
      actions.purchased()
      fetchUser().then()
      NiceModal.hide(MODAL_ID.marketplace.productBuy).then()
      success?.onDone?.()
      notifyPurchaseSuccess(() => goTo.inventory())
    }

    const handleCancel = () => actions.cancel()

    const timeoutModal = {
      overlayClassName: 'z-confirm',
      onCancel: () => {
        NiceModal.hide(MODAL_ID.marketplace.productBuy).then()
        NiceModal.hide(MODAL_ID.boolean).then()
      },
      children: (
        <div className="text-center">
          <ModalStateGraphic
            icon="timer"
            className="mb-2 mx-auto"
            iconClassName="w-[44px] h-[44px]"
          />
          <h1 className="h3 mb-2">Reservation Expired</h1>
          <p className="body-sm text-gray-300">
            This reservation has expired and is now available for purchase by
            anyone on the marketplace. {timeout?.message}
          </p>
        </div>
      ),
      primaryButton: {
        label: timeout?.label,
        loading: isRenewing,
        onClick: handleRetry
      }
    }

    const isLoading = isRenewing || isLoadingUser

    if (isRenewError) return null

    return (
      <ViewModal
        id={MODAL_ID.marketplace.productBuy}
        hasLogoMobile={false}
        headerChildren={
          !isLoading &&
          !didPurchase && (
            <TimeoutCounter
              className="w-full"
              timeout={reservation.listing.timeout}
              timeoutMax={reservation.listing.max_timeout}
              timeoutId={reservation.reservation_id}
              onComplete={handleTimeout}
            />
          )
        }
        cancelButtonConfig={{
          action: handleCancel
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <InventoryBuyItem
            preReservePrice={preReservePrice}
            onSuccess={handleSuccess}
            reservation={reservation}
            walletBalance={walletBalance}
          />
        )}
      </ViewModal>
    )
  }
)
