import React from 'react'
import { classNames } from '@/util/tailwindHelpers'
import { andUpSuffix } from '@/util/assetHelpers'
import { USDC } from './USDC'

type Props = {
  price: number
  className?: string
  suffixClassName?: string
  hideAndUpSuffix?: boolean
  listingsCount?: number
  includeCurrencySuffix?: boolean
}

export default function ProductPrice({
  className,
  hideAndUpSuffix,
  listingsCount,
  ...rest
}: Props) {
  return (
    <USDC className={classNames('text-success', className)} {...rest}>
      {!hideAndUpSuffix && andUpSuffix(hideAndUpSuffix, listingsCount)}
    </USDC>
  )
}
