import { ReactNode } from 'react'
import { classNames } from '@/util/tailwindHelpers'
import { UserAsset } from '@/api/resources/user/asset/get/schema'
import Button, { ButtonTheme } from '@/components/Button'
import { useAssetListing } from '@/hooks/useAssetListing'
import { useNavigation } from '@/hooks/useNavigation'
import { DropConfig } from '@/config/drops/schema'
import { useWallet } from '@/features/wallet/providers/wallet'
import { useEditListingMutation } from '@/api/resources/user/listing/edit'
import Toast from '@/components/Toast'
import { useInventory } from '@/hooks/useInventory'

const BUTTON_CLASSES = 'w-full'

type EditListingButtonProps = {
  className?: string
  disabled?: boolean
  loading?: boolean
  theme: ButtonTheme
  onClick: () => void
  children: ReactNode
}

export interface Props {
  asset: UserAsset
  drop: DropConfig
  className?: string
}

export const PrivateInventoryMarketplaceActions = ({
  asset,
  className
}: Props) => {
  const { isWalletLoading, isKycVerified, migrationNeeded } = useWallet()
  const { goTo } = useNavigation()

  const {
    marketplace: { status }
  } = asset

  const isProductActive = status === 'active'
  const isProductReserved = status === 'pending'

  const { openListing } = useAssetListing({
    assetId: asset.id,
    isKycVerified,
    goTo
  })

  const handleListItemModal = async () => openListing()

  let buttonProps: EditListingButtonProps = {
    className: BUTTON_CLASSES,
    loading: isWalletLoading,
    theme: 'green',
    onClick: handleListItemModal,
    children: 'List On Market'
  }

  if (isProductActive) {
    buttonProps = {
      ...buttonProps,
      theme: 'white',
      children: migrationNeeded ? 'Edit Inactive Listing' : 'Edit Listing'
    }
  } else if (isProductReserved) {
    buttonProps = {
      ...buttonProps,
      theme: 'white',
      children: 'Sale Pending',
      disabled: true
    }
  } else if (migrationNeeded) {
    buttonProps = {
      ...buttonProps,
      theme: 'solana',
      children: 'Migrate Wallet to List',
      onClick: () => goTo.migrateWallet()
    }
  }

  return (
    <div className={classNames('space-y-2', className)}>
      {!migrationNeeded && <ActivateListingButton asset={asset} />}
      <Button {...buttonProps} />
    </div>
  )
}

interface ActivateListingButtonProps {
  asset: UserAsset
}

const ActivateListingButton = ({ asset }: ActivateListingButtonProps) => {
  const { refreshAssets } = useInventory()

  const { mutate, isLoading } = useEditListingMutation(
    asset.marketplace.listing_id,
    {
      onSuccess: () => {
        refreshAssets()
        Toast({ description: 'Listing Has Been Reactivated', type: 'success' })
      }
    }
  )

  if (!asset.marketplace.migrating) {
    return null
  }

  const handleActivateClick = async () => {
    mutate({ price: asset.marketplace.listing_price })
  }

  return (
    <Button
      className={BUTTON_CLASSES}
      theme="green"
      loading={isLoading}
      onClick={handleActivateClick}
    >
      Activate Listing
    </Button>
  )
}

export default PrivateInventoryMarketplaceActions
