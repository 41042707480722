import React from 'react'

type ModalSequenceGoTo<T> = {
  (target: T): void
  (arg0: T): void
}

interface ModalSequenceIndicatorProps<T> {
  steps: T[]
  currentStep: number
  goTo: ModalSequenceGoTo<T>
}

const ModalSequenceIndicator = <T extends string>({
  steps,
  currentStep,
  goTo
}: ModalSequenceIndicatorProps<T>) => {
  return (
    <div className="gap-1 w-full flex flex-1 flex-row items-center justify-center mt-3">
      {steps.map((id, i) => (
        <div
          key={id}
          onClick={() => goTo(id)}
          className={`h-1 rounded ${
            i == currentStep ? 'w-3 bg-white' : 'w-1 bg-gray-600'
          }`}
        />
      ))}
    </div>
  )
}

export default ModalSequenceIndicator
