import { useWallet } from '@/features/wallet/providers/wallet'
import { PurchaseIntent } from '@/components/Modals/ModalV2/Web3Transaction/PurchaseApproval'
import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { useAuth } from '@/contexts/auth'
import {
  buildPurchase,
  encodeTransaction,
  getDisplayAddress,
  getUsdcAccount,
  toPublicKey,
  UsdcAccount,
  UsdcRecipient
} from '@/util/solanaHelper'
import { getSolanaRecentBlockhash } from '@/api/resources/solana/recent'
import { getTransactionSigner } from '@/features/wallet/utils/turnkey'
import { submitWeb3Purchase } from '@/api/resources/user/web3/purchase'
import { purchaseListingStart } from '@/api/resources/user/listing/purchase/start'
import { ReservedListing } from '@/api/resources/shared/listingReserve'
import { purchaseListingComplete } from '@/api/resources/user/listing/purchase/complete'
import { useCountdown } from '@/hooks/useCountdown'
import { checkSolanaAccountExists } from '@/api/resources/solana/exists'

type UsePurchaseListingInput = {
  reservation: ReservedListing
}

type UsePurchaseListingResult = {
  submitPurchase: () => Promise<boolean>
}

type ReservedPurchase = {
  amount: number
  buyer: UsdcAccount
  seller: UsdcRecipient
}

type StartedPurchase = ReservedPurchase & {
  netAmount: number
  netFee: number
  priorityRate: number
}

export const usePurchaseListing = ({
  reservation
}: UsePurchaseListingInput): UsePurchaseListingResult => {
  const { user } = useAuth()
  const { wallet, getCredentials, isAutoApproveEnabled } = useWallet()
  const expiresSeconds = useCountdown(reservation.listing.timeout)

  const initPurchase = async (): Promise<ReservedPurchase> => {
    const { listing, buyer_address, seller_address } = reservation

    const buyer = {
      ownerAddress: toPublicKey(buyer_address.address),
      tokenAddress: toPublicKey(buyer_address.associated_address)
    }

    const sellerHasUsdcAddress = !!seller_address.associated_address

    const sellerUsdcAccount = sellerHasUsdcAddress
      ? {
          ownerAddress: toPublicKey(seller_address.address),
          tokenAddress: toPublicKey(seller_address.associated_address)
        }
      : getUsdcAccount(seller_address.address)

    let sellerUsdcAccountExists: boolean

    if (!sellerHasUsdcAddress) {
      const checkResponse = await checkSolanaAccountExists({
        address: sellerUsdcAccount.tokenAddress.toString()
      })
      sellerUsdcAccountExists = checkResponse.exists
    } else {
      sellerUsdcAccountExists = true
    }

    const seller = { ...sellerUsdcAccount, exists: sellerUsdcAccountExists }

    return {
      amount: listing.listing_price,
      buyer,
      seller
    }
  }

  const startPurchase = async (
    reservedPurchase: ReservedPurchase
  ): Promise<StartedPurchase> => {
    const { listing, solana_priority_rate } = await purchaseListingStart(
      reservation.reservation_id
    )

    return {
      netAmount: listing.net_amount,
      netFee: listing.net_fee,
      priorityRate: solana_priority_rate,
      ...reservedPurchase
    }
  }

  const completePurchase = async (txnHash: string): Promise<number> => {
    const result = await purchaseListingComplete({
      reservation_id: reservation.reservation_id,
      tx_hash: txnHash
    })

    return result.balance.balance
  }

  const approvePurchase = async (
    reservedPurchase: ReservedPurchase
  ): Promise<string | undefined> => {
    const { amount, buyer, seller } = reservedPurchase

    const buyingParty = {
      address: getDisplayAddress(buyer.ownerAddress.toString()),
      avatarUrl: user?.avatar_media?.size0_url
    }

    const sellingParty = {
      address: getDisplayAddress(seller.ownerAddress.toString()),
      avatarUrl: reservation.seller?.media.avatar_media?.size0_url
    }

    const purchaseIntent: PurchaseIntent = {
      amount,
      buyer: buyingParty,
      seller: sellingParty,
      expiresSeconds
    }

    const existingCredentials = isAutoApproveEnabled && (await getCredentials())

    return new Promise(resolve => {
      const handleApproved = (credentialBundle: string) =>
        resolve(credentialBundle)

      const handleCancel = () => resolve(undefined)

      if (!existingCredentials) {
        showModal(MODAL_ID.web3.approvePurchase, {
          purchaseIntent,
          onApproved: handleApproved,
          onCancel: handleCancel,
          overlayClassName: 'z-viewModal'
        })
      } else {
        resolve(existingCredentials)
      }
    })
  }

  const purchaseListing = async (
    txnAuthBundle: string,
    startedPurchase: StartedPurchase
  ) => {
    const { tk_suborg_id: organizationId } = wallet
    const { netAmount, netFee, buyer, seller, priorityRate } = startedPurchase
    const signer = await getTransactionSigner(organizationId, txnAuthBundle)

    const { blockhash } = await getSolanaRecentBlockhash()

    const purchaseTxn = buildPurchase(
      buyer,
      seller,
      blockhash,
      netAmount,
      netFee,
      priorityRate
    )

    await signer.addSignature(purchaseTxn, buyer.ownerAddress.toString())

    const encodedTransaction = encodeTransaction(purchaseTxn)

    const { txnHash } = await submitWeb3Purchase({
      transaction: encodedTransaction
    })

    return txnHash
  }

  const submitPurchase = async () => {
    const reservedPurchase = await initPurchase()

    const txnAuthBundle = await approvePurchase(reservedPurchase)
    if (!txnAuthBundle) return

    const startedPurchase = await startPurchase(reservedPurchase)

    const txnHash = await purchaseListing(txnAuthBundle, startedPurchase)
    if (!txnHash) return false

    await completePurchase(txnHash)
    return true
  }

  return { submitPurchase }
}
