import React, { ReactNode } from 'react'
import DefaultModal from '../../..'
import { MODAL_ID } from '@/constants/modalId'
import NiceModal from '@ebay/nice-modal-react'
import { ModalFooterButtons } from '../../../ModalFooterButtons'
import ModalStateGraphic from '../../../ModalStateGraphic'
import { ModalButtonProps } from '../../../BaseModal'

interface Props {
  title?: ReactNode
  description?: ReactNode
  primaryButton: ModalButtonProps
  secondaryButton?: ModalButtonProps
}

export default NiceModal.create(
  ({
    title = 'Wallet Creation Finished',
    description = 'Your wallet is created and connected to Droppp. You can now buy and list items on Droppp Marketplace.',
    primaryButton,
    secondaryButton
  }: Props) => {
    return (
      <DefaultModal
        id={MODAL_ID.wallet.create.success}
        title={title}
        titleClassName="text-center h4"
      >
        {({ ModalBody, ModalHeaderMedia, ModalFooter }) => {
          return (
            <>
              <ModalHeaderMedia
                content={{
                  component: (
                    <ModalStateGraphic
                      icon="tick"
                      theme="solana"
                      className="mx-auto"
                    />
                  )
                }}
              />
              <ModalBody>
                <p className="text-gray-300 text-center -mt-1">{description}</p>
              </ModalBody>
              <ModalFooter>
                <ModalFooterButtons
                  primaryButton={primaryButton}
                  secondaryButton={secondaryButton}
                />
              </ModalFooter>
            </>
          )
        }}
      </DefaultModal>
    )
  }
)
