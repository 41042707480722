import classnames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'
import { designSystem } from '@/util/tailwindHelpers'

type Theme = 'gray300' | 'dropppRainbow' | 'solanaRainbow'

interface Props {
  className?: string
  size?: 'large' | 'medium' | 'small' | 'tiny'
  theme?: Theme
}

const Loading = ({
  className,
  size,
  theme = 'dropppRainbow',
  ...props
}: Props) => {
  return (
    <svg
      className={classnames(styles.container, styles[size], className)}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Gradient theme={theme} />
      <circle
        stroke={solidColor(theme) || `url(#${theme})`}
        cx="33"
        cy="33"
        r="25"
      ></circle>
    </svg>
  )
}

const solidColor = (theme: Theme): string | undefined => {
  switch (theme) {
    case 'gray300':
      return designSystem.colors['gray-300']
    default:
      return undefined
  }
}

const Gradient = ({ theme }: { theme: Theme }): JSX.Element => {
  switch (theme) {
    case 'gray300':
      return undefined
    case 'dropppRainbow':
      return (
        <linearGradient id={theme}>
          <stop offset="0%" stopColor="#f2a778" />
          <stop offset="50%" stopColor="#ea35cf" />
          <stop offset="100%" stopColor="#6951e2" />
        </linearGradient>
      )
    case 'solanaRainbow':
      return (
        <linearGradient id={theme}>
          <stop offset="0%" stopColor="#00FFA3" />
          <stop offset="100%" stopColor="#DC1FFF" />
        </linearGradient>
      )
  }
}

export default Loading
