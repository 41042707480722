import classNames from 'classnames'
import { ToastMessage } from '.'

const SolanaToastMessage = ({ ...props }) => {
  return (
    <div
      className={classNames(
        'bg-gray-900 rounded-2xl relative',
        'border-solana after:inset-0'
      )}
    >
      <ToastMessage
        className="!bg-solana--linear-opaque"
        iconClassName="bg-white"
        {...props}
      />
    </div>
  )
}

export default SolanaToastMessage
