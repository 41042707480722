import { z } from 'zod'
import { successResponseSchema } from '@/api/resources/shared/success'

export const web3PurchaseInputSchema = z.object({
  transaction: z.string()
})

export const web3PurchaseResponseSchema = successResponseSchema.extend({
  txnHash: z.string()
})

export type Web3PurchaseInput = z.infer<typeof web3PurchaseInputSchema>
export type Web3PurchaseResponse = z.infer<typeof web3PurchaseResponseSchema>
