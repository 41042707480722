import { z } from 'zod'
import { kycFlagsSchema } from '@/api/resources/shared/account'
import { web3WalletSchema } from '@/api/resources/user/web3/shared'
// turn on once BE pushed up a fix
// import { successResponseSchema } from '@/api/resources/shared/success'

export const walletSchema = z
  .object({
    balance: z.number().nullable(),
    has_deposited: z.boolean(),
    minimum_met: z.boolean(),
    verify_id_prompted: z.boolean(),
    withdraw_fee: z.number(),
    migration_needed: z.boolean().nullable().optional(),
    migration_step: z.number().nullable().optional(),
    withdraw_fees: z.object({
      POLY: z.number(),
      ETH: z.number(),
      SOL: z.number()
    }),
    web3data: web3WalletSchema.nullable()
  })
  .merge(kycFlagsSchema)

export const walletGetResponseSchema = walletSchema

// turn on once BE pushed up a fix
// export const walletGetResponseSchema = successResponseSchema.extend({
//   wallet: walletSchema
// })

export type Wallet = z.infer<typeof walletSchema>
export type WalletGetResponse = z.infer<typeof walletGetResponseSchema>
