import { z } from 'zod'
import { registeredWeb3WalletSchema } from '@/api/resources/user/web3/shared'

// we use the passkey shape provided by TurnkeyPasskeyClient `createUserPasskey`
// https://github.com/tkhq/sdk/blob/main/packages/http/src/webauthn.ts#L99
// https://github.com/tkhq/sdk/blob/main/packages/sdk-browser/src/sdk-client.ts#L299
export const passkeySchema = z.object({
  encodedChallenge: z.string(),
  attestation: z.object({
    credentialId: z.string(),
    attestationObject: z.string(),
    clientDataJson: z.string(),
    transports: z.array(z.string())
  })
})

export const web3RegisterInputSchema = z.object({
  passkey: passkeySchema.required()
})

export const web3RegisterResponseSchema = registeredWeb3WalletSchema

export type Passkey = z.infer<typeof passkeySchema>
export type Web3RegisterInput = z.infer<typeof web3RegisterInputSchema>
export type Web3RegisterResponse = z.infer<typeof web3RegisterResponseSchema>
