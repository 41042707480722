import { postJSON } from '@/api/core/http/post'

import {
  Web3RegisterInput,
  Web3RegisterResponse,
  web3RegisterResponseSchema
} from './schema'

const path = '/user/web3/register/'

export const registerWeb3Wallet = async (
  input: Web3RegisterInput
): Promise<Web3RegisterResponse> =>
  postJSON<Web3RegisterResponse>(path, input, {}, web3RegisterResponseSchema)
