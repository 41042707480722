import DefaultModal from '..'
import { classNames } from '@/util/tailwindHelpers'
import styles from './styles.module.scss'

const SolanaModal = ({ children, id, ...props }) => {
  return (
    <DefaultModal
      {...props}
      id={id}
      className={classNames(styles.solanaGradient, 'border-solana--circle')}
    >
      {children}
    </DefaultModal>
  )
}

export default SolanaModal
