import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps,
  ModalSequenceChildren
} from '@/components/Modals/ModalV2/ModalSequence'

export const WithdrawalSteps = {
  cryptoExchangeStep: 'cryptoExchangeStep',
  enterAddress: 'enterAddressStep',
  enterAmount: 'enterAmountStep',
  finalize: 'finalizeStep'
} as const

export type WithdrawalStep =
  (typeof WithdrawalSteps)[keyof typeof WithdrawalSteps]

export type Withdrawal = Partial<{
  address: string
  amount: number
}>

export type WithdrawalSequence = IModalSequenceControllerProps
export type WithdrawalStepConfiguration = IModalSequenceViewProps

export type AddressPayload = { address: string }
export type AmountPayload = { amount: number }

export type WithdrawalLimit = {
  value: number
  formatted: string
}

export type WithdrawalTransition = {
  step: WithdrawalStep
  sequence: WithdrawalSequence
}

export type WithdrawalContextType = {
  balance: number
  withdrawalFee: number
  withdrawal: Withdrawal
  minimum: WithdrawalLimit
  maximum: WithdrawalLimit
  transitionTo: (transition: WithdrawalTransition) => void
  setAddress: (address: string) => void
  setAmount: (amount: number) => void
  submitWithdrawal: (mfaCode?: string) => Promise<boolean>
}

export type WithdrawalStepProps = {
  sequence: WithdrawalSequence
} & ModalSequenceChildren
