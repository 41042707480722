import classNames from 'classnames'
import Badge, { BadgeProps } from '.'
import { iconClass } from './IconClasses'

interface Props {
  size?: BadgeProps['size']
}

const InActiveBadge = ({ size = 'default' }: Props) => {
  return (
    <Badge
      size={size}
      icon="forSale"
      label="InActive"
      hideLabel={size == 'fluid-sm'}
      iconClass={iconClass(size)}
      className={classNames('!border-alert', {
        '!px-[.4em]': size == 'fluid-sm'
      })}
    />
  )
}

export default InActiveBadge
