import { classNames } from '@/util/tailwindHelpers'

export const ListedStateMessage = ({ isCatalogAsset, migrating }) => {
  const shouldShowListed = !isCatalogAsset && !migrating

  if (isCatalogAsset && !migrating) return null

  let message = ''
  if (shouldShowListed) {
    message = 'Listed'
  } else if (migrating) {
    message = 'Inactive'
  }

  return (
    <span
      className={classNames({
        'text-green': shouldShowListed,
        'text-alert': migrating
      })}
    >
      {message}{' '}
    </span>
  )
}
