import { ActionSection } from '@/components/ActionSection'
import { classNames, designSystem } from '@/util/tailwindHelpers'
import Icon from '@/components/Icon'
import { showModal } from '@/components/Modals/ModalV2'
import RoundedBox from '@/components/RoundedBox'
import Link from 'next/link'
import React, { ComponentPropsWithoutRef } from 'react'
import { RECOVERY_EMAIL_MODAL_ID } from './constants'
import { useAuth } from '@/contexts/auth'
import { useWallet } from '@/features/wallet/providers/wallet'

interface Props extends ComponentPropsWithoutRef<'div'> {}

export default function RecoveryEmailSection({ className, ...rest }: Props) {
  const { user } = useAuth()
  const { wallet } = useWallet()

  const dropppEmail = user.email
  const recoveryEmail = wallet.tk_email

  const doNotMatch =
    dropppEmail.trim().toLowerCase() !== recoveryEmail.trim().toLowerCase()

  return (
    <div className={classNames('space-y-2', className)} {...rest}>
      <ActionSection
        sectionTitle="Wallet Recovery Email"
        primaryAction={{
          label: 'Change',
          action: () => showModal(RECOVERY_EMAIL_MODAL_ID)
        }}
      >
        Update the email address used to reconnect your wallet if your passkeys
        are unavailable.
      </ActionSection>
      {/* Refactor */}
      <RoundedBox className="bg-gray-800 px-[20px] py-2 rounded-2xl flex justify-between">
        <span className="text-gray-300">Email</span>
        <span>{recoveryEmail}</span>
      </RoundedBox>
      {doNotMatch ? (
        <div className="flex gap-1">
          <Icon
            name="informationalFilled"
            style={{ '--iconTextColor': designSystem.colors.white }}
            className="text-blue flex-shrink-0 mt-[2px]"
          />
          <p className="body-sm text-gray-300">
            Droppp account email (
            <span className="text-white">{dropppEmail}</span>) does not match
            your wallet recovery email. You may update your wallet recovery
            email to match the account email, or you can leave them as they are.
            Both options are perfectly acceptable.{' '}
            <Link className="inline-link" href="/account">
              Change
            </Link>
          </p>
        </div>
      ) : null}
    </div>
  )
}
