import React from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from '@/components/Modals/ModalV2/ModalSequence'
import { ModalFooterButtons } from '@/components/Modals/ModalV2/ModalFooterButtons'

export const VerifyEmail = (
  sequenceProps: IModalSequenceControllerProps
): IModalSequenceViewProps => {
  return {
    id: 'check-email',
    title: 'Check your email to verify your wallet',
    overlayHeaderOpaqueOnScroll: true,
    // @TODO - Update image once created
    header: {
      image: {
        path: 'global/modals/',
        id: 'wallet-mon_1',
        alt: 'header image',
        width: 480,
        height: 300
      }
    },
    view: ({ ModalBody, ModalFooter }) => (
      <>
        <ModalBody>
          <p className="text-gray-300">
            We've sent a verification link to the email associated with your
            Droppp account.{' '}
            <span className="text-alert">
              Using the same device and browser, click the link in the email
              sent by Turnkey
            </span>{' '}
            to continue connecting your wallet.
          </p>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons
            primaryButton={{
              label: 'Close',
              onClick: sequenceProps.close
            }}
          />
        </ModalFooter>
      </>
    )
  }
}
