import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'
import {
  Web3PurchaseCompleteInput,
  Web3PurchaseCompleteResponse,
  web3PurchaseCompleteResponseSchema
} from './schema'

const path = `/user/listing/purchase/complete`

export const purchaseListingComplete = async (
  input: Web3PurchaseCompleteInput
): Promise<Web3PurchaseCompleteResponse> =>
  post<Web3PurchaseCompleteResponse>(
    buildApiUrl(path, input),
    {},
    web3PurchaseCompleteResponseSchema
  )
