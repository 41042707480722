import { PropsWithChildren } from 'react'
import { USDC_CURRENCY_SUFFIX } from '@/constants/currency'
import { formatUSDC } from '@/util/currencyHelpers'
import { classNames } from '@/util/tailwindHelpers'

interface USDCPriceProps extends PropsWithChildren {
  price: number
  className?: string
  removeDecimalWhenZero?: boolean
  suffixClassName?: string
}

export const USDC = ({
  price,
  className,
  suffixClassName,
  removeDecimalWhenZero,
  children
}: USDCPriceProps) => {
  let amount = formatUSDC(price, { includeUSDCSuffix: false })

  if (removeDecimalWhenZero && price === 0) {
    amount = '0'
  }

  return (
    <span className={className}>
      {amount}
      <span className={classNames('text-gray-300', suffixClassName)}>
        {' '}
        {USDC_CURRENCY_SUFFIX}
        {children}
      </span>
    </span>
  )
}
