import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'
import {
  Web3SetInput,
  Web3SetResponse,
  web3SetResponseSchema
} from '@/api/resources/user/web3/set/schema'

const path = '/user/web3/set'

export const setWeb3Wallet = async (
  input: Web3SetInput
): Promise<Web3SetResponse> =>
  post<Web3SetResponse>(buildApiUrl(path, input), web3SetResponseSchema)
