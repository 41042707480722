import React, { useState } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { useForm } from 'react-hook-form'
import { useWithdrawFunds } from '@/features/wallet/modals/withdraw/WithdrawFundsProvider'
import { Button, Separator, Toast } from '@/components'
import * as modalProps from '@/components/Modals/ModalV2/shared/props'
import { DetailItem } from '@/features/wallet/components/DetailItem'
import Icon from '@/components/Icon'
import Tooltip from '@/components/Tooltip'
import { BreakdownItem } from '@/features/wallet/components/BreakdownItem'
import {
  WithdrawalSequence,
  WithdrawalStepConfiguration,
  WithdrawalStepProps,
  WithdrawalSteps
} from '@/features/wallet/modals/withdraw/types'
import TwoFAModal from '@/components/Modals/ModalV2/TwoFAModal'
import { isApiError } from '@/api/core/errors'
import { notifyGenericError } from '@/components/QueryErrorNotifier/notifiers/Generic'
import { hideModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { USDC } from '@/components/USDC'

const notifySuccess = () => {
  Toast({
    type: 'success',
    description: 'Your withdraw request is being processed'
  })
}

const FinalizeStep = ({ ModalBody, ModalFooter }: WithdrawalStepProps) => {
  const ModalTwoFA = useModal(TwoFAModal)
  const [mfaCode, setMfaCode] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { withdrawalFee, withdrawal, submitWithdrawal } = useWithdrawFunds()

  const { handleSubmit } = useForm()

  const handle2FAError = async () => {
    const twoFaCode = await new Promise(resolve =>
      ModalTwoFA.show({ onAuthorized: resolve as any })
    )
    setMfaCode(twoFaCode as string)
  }

  const handleError = (err: unknown) => {
    if (isApiError(err) && err.is2FAAuthCodeRequest) {
      if (!ModalTwoFA.visible) handle2FAError().then()
    } else {
      notifyGenericError({ error: err })
    }
  }

  const handleSuccess = () => {
    notifySuccess()
    hideModal(MODAL_ID.web3.withdrawal)
  }

  const submitForm = handleSubmit(async () => {
    setIsSubmitting(true)
    try {
      const success = await submitWithdrawal(mfaCode)
      if (success) handleSuccess()
    } catch (error) {
      handleError(error)
    } finally {
      setMfaCode(null)
      setIsSubmitting(false)
    }
  })

  const { amount, address } = withdrawal
  const total = amount - withdrawalFee
  const shouldDisableSubmit = total <= 0

  return (
    <form onSubmit={submitForm}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <DetailItem
            title="To"
            value={<span className="break-all">{address}</span>}
          />

          <div className="grid grid-cols-2 gap-4 mb-1 [&>:not(:last-child)]:border-r [&>:not(:last-child)]:border-defaultBorder">
            <DetailItem
              title="Network"
              value={
                <>
                  <div className="flex gap-[4px] h-3 items-center">
                    <Icon name="solana" className="w-[20px] md:w-3" />
                    Solana
                  </div>
                </>
              }
            />
            <DetailItem
              title={
                <Tooltip
                  trigger={({ DefaultTriggerComponent }) => (
                    <div className="flex items-center gap-1 relative">
                      <span>Est. Arrival of Funds</span>
                      <DefaultTriggerComponent className="outline" />
                    </div>
                  )}
                >
                  Sending a blockchain transaction may take a few minutes to
                  process.
                </Tooltip>
              }
              value="5–15 minutes"
            />
          </div>

          <Separator className="-mx-[var(--modalPadding)] !border-gray-900" />

          <div>
            <h3 className="h7 mb-2">Breakdown</h3>
            <ul className="flex flex-col gap-1">
              <li>
                <BreakdownItem title={'Withdraw Amount'} value={amount} />
              </li>
              <li>
                <BreakdownItem
                  title={
                    <Tooltip
                      trigger={({ DefaultTriggerComponent }) => (
                        <div className="flex gap-1 items-center">
                          Processing Fee
                          <DefaultTriggerComponent />
                        </div>
                      )}
                    >
                      A small amount paid by the person who sends a transaction
                      to ensure that the transaction gets processed by the
                      network.
                    </Tooltip>
                  }
                  value={withdrawalFee}
                />
              </li>
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-between mb-3">
          <p className="h5">Withdraw Total</p>
          <p className="pricing-xl">
            <USDC price={total} />
          </p>
        </div>
        <Button
          {...modalProps.submit}
          theme="rainbow"
          loading={isSubmitting}
          disabled={shouldDisableSubmit}
        >
          WITHDRAW FUNDS
        </Button>
      </ModalFooter>
    </form>
  )
}

FinalizeStep.configure = (
  sequence: WithdrawalSequence
): WithdrawalStepConfiguration => {
  return {
    id: WithdrawalSteps.finalize,
    title: 'Confirm Withdraw',
    onRetreat: () => sequence.goTo(WithdrawalSteps.enterAmount),
    view: props => <FinalizeStep sequence={sequence} {...props}></FinalizeStep>
  }
}

export { FinalizeStep }
