import { z } from 'zod'
import {
  listingBalanceSchema,
  listingPurchaseSchema
} from '@/api/resources/user/listing/purchase/shared'

export const web3PurchaseCompleteInputSchema = z.object({
  reservation_id: z.string(),
  tx_hash: z.string()
})

export const web3PurchaseCompleteSchema = z.object({
  balance: listingBalanceSchema,
  listing: listingPurchaseSchema
})

export const web3PurchaseCompleteResponseSchema = web3PurchaseCompleteSchema

export type Web3PurchaseCompleteInput = z.infer<
  typeof web3PurchaseCompleteInputSchema
>

export type Web3PurchaseCompleteResponse = z.infer<
  typeof web3PurchaseCompleteResponseSchema
>
