import { TurnkeySigner } from '@turnkey/solana'
import {
  IframeStamper,
  Turnkey,
  TurnkeyIframeClient,
  TurnkeyPasskeyClient,
  type TurnkeySDKApiTypes
} from '@turnkey/sdk-browser'
import { User } from '@/api/resources/shared/user'
import { registerWeb3Wallet } from '@/api/resources/user/web3/register'
import { setWeb3Wallet } from '@/api/resources/user/web3/set'
import { Web3Wallet } from '@/api/resources/user/web3/shared'
import { AccountWallet, ConnectedWallet } from '@/features/wallet/types'
import { recoverWeb3Wallet } from '@/api/resources/user/web3/recover'
import { logSessionEvent } from '@/util/logger'
import { walletPublicConfig } from '@/config/wallet'

type IFrameConfig = {
  containerId: string
  iFrameId: string
  url: string
}

const TK_AUTH_RECOVERY_IFRAME = {
  containerId: 'turnkey-auth-iframe-container-id',
  iFrameId: 'turnkey-recovery-iframe',
  url: 'https://recovery.turnkey.com'
} as const

export const TK_EXPORT_WALLET_IFRAME = {
  containerId: 'turnkey-export-iframe-container-id',
  iFrameId: 'turnkey-export-iframe',
  url: 'https://export.turnkey.com'
} as const

const createPasskey = async (
  user: User,
  passkeyClient: TurnkeyPasskeyClient
) => {
  const name = `Droppp Wallet ${new Date().toLocaleDateString()}`
  return await passkeyClient.createUserPasskey({
    publicKey: {
      user: {
        name,
        email: user.email,
        displayName: name
      },
      pubKeyCredParams: [
        {
          type: 'public-key',
          alg: -7
        },
        {
          type: 'public-key',
          alg: -257
        }
      ]
    }
  })
}

export const performRegister = async (
  user: User,
  passkeyClient: TurnkeyPasskeyClient
) => {
  const passkey = await createPasskey(user, passkeyClient)
  const registeredWallet = await registerWeb3Wallet({ passkey })
  await setWeb3Wallet(registeredWallet)
  return { passkey, registeredWallet }
}

export const resolveAccountWallet = (
  web3Wallet?: Web3Wallet,
  connectedWallet?: ConnectedWallet
): AccountWallet => {
  if (!web3Wallet) return { status: 'none', wallet: null }

  if (!connectedWallet)
    return {
      status: 'disconnected',
      statusReason: 'isNotStored',
      wallet: web3Wallet
    }

  if (web3Wallet.tk_suborg_id !== connectedWallet.tk_suborg_id)
    return {
      status: 'disconnected',
      statusReason: 'doesNotMatch',
      wallet: web3Wallet
    }

  return {
    status: 'connected',
    wallet: web3Wallet
  }
}

const buildIFrameStamper = (config: IFrameConfig = TK_AUTH_RECOVERY_IFRAME) => {
  return new IframeStamper({
    iframeUrl: config.url,
    iframeContainer: document.getElementById(config.containerId),
    iframeElementId: config.iFrameId
  })
}

const resetIFrameStamper = (config: IFrameConfig = TK_AUTH_RECOVERY_IFRAME) => {
  const frame = document.getElementById(config.iFrameId)
  if (frame && frame.parentNode) frame.parentNode.removeChild(frame)
}

const buildIFrameClient = (
  IFrameStamper: IframeStamper,
  organizationId: string
) =>
  new TurnkeyIframeClient({
    apiBaseUrl: walletPublicConfig.tkApiBaseUrl,
    stamper: IFrameStamper,
    organizationId
  })

export const startUserRecovery = async () => {
  resetIFrameStamper()
  const recoveryStamper = buildIFrameStamper()
  const publicKey = await recoveryStamper.init()
  await recoverWeb3Wallet({ publicKey })
  logSessionEvent(`[wallet][recovery][started] publicKey: ${publicKey}`)
}

export const finishUserRecovery = async (
  credentialBundle: string,
  wallet: Web3Wallet,
  user: User,
  passkeyClient: TurnkeyPasskeyClient
) => {
  const iframeStamper = buildIFrameStamper()

  const publicKey = await iframeStamper.init()
  logSessionEvent(`[wallet][recovery][finishing] publicKey: ${publicKey}`)

  const injected = await iframeStamper.injectCredentialBundle(credentialBundle)
  logSessionEvent(
    `[wallet][recovery][finishing] credentials injected: ${injected}`
  )

  const passkey = await createPasskey(user, passkeyClient)

  const { tk_user_id: userId, tk_suborg_id: organizationId } = wallet

  const iframeClient = buildIFrameClient(iframeStamper, organizationId)
  const timestampMs = String(Date.now())

  const userName = user.account_wax || user.email

  await iframeClient.recoverUser({
    timestampMs,
    organizationId,
    userId,
    authenticator: {
      authenticatorName: `Droppp Authenticator - ${userName} (Recovered)`,
      challenge: passkey.encodedChallenge,
      attestation: passkey.attestation
    }
  })

  iframeStamper.clear()
  return passkey
}

export const findSessionCredentialId = (
  session: TurnkeySDKApiTypes.TCreateReadOnlySessionResponse
) => {
  const soleVote =
    session?.activity?.votes?.length > 0 && session.activity.votes[0]

  if (soleVote) {
    const authenticators = soleVote.user?.authenticators

    const matchingAuthenticator = authenticators?.find(
      authenticator => soleVote.publicKey === authenticator.credential.publicKey
    )

    return matchingAuthenticator?.credentialId
  }
}

const buildTransactionClient = async (organizationId: string) => {
  resetIFrameStamper()
  const iframeStamper = buildIFrameStamper()
  await iframeStamper.init()
  return buildIFrameClient(iframeStamper, organizationId)
}

export const getTransactionSigner = async (
  organizationId: string,
  authBundle: string
) => {
  const client = await buildTransactionClient(organizationId)
  await client.injectCredentialBundle(authBundle)
  return new TurnkeySigner({ organizationId, client })
}

// 20 minutes in seconds
const TK_SESSION_TIMEOUT_SECONDS = '1200'

export const getReadWriteSession = async (turnkeyBrowser: Turnkey) =>
  await turnkeyBrowser.getReadWriteSession()

export const loginReadWriteSession = async (
  organizationId: string,
  passkeyClient: TurnkeyPasskeyClient
) => {
  const client = await buildTransactionClient(organizationId)

  const { credentialBundle } = await passkeyClient.loginWithReadWriteSession(
    client.iframePublicKey!,
    TK_SESSION_TIMEOUT_SECONDS
  )

  return credentialBundle
}

export const exportUserWallet = async (
  walletId: string,
  organizationId: string,
  passkeyClient: TurnkeyPasskeyClient
): Promise<boolean> => {
  resetIFrameStamper(TK_EXPORT_WALLET_IFRAME)
  const iframeStamper = buildIFrameStamper(TK_EXPORT_WALLET_IFRAME)
  const targetPublicKey = await iframeStamper.init()
  const timestampMs = String(Date.now())

  const response = await passkeyClient.exportWallet({
    walletId,
    organizationId,
    targetPublicKey,
    timestampMs
  })

  await iframeStamper.applySettings({
    styles: {
      color: '#ffffff'
    }
  })

  return await iframeStamper.injectWalletExportBundle(
    response.exportBundle,
    organizationId
  )
}

export const updateUserEmail = async (
  userId: string,
  organizationId: string,
  email: string,
  passkeyClient: TurnkeyPasskeyClient
) => {
  const timestampMs = String(Date.now())
  return await passkeyClient.updateUser({
    userId,
    userEmail: email,
    userTagIds: [],
    organizationId,
    timestampMs
  })
}
