import React from 'react'
import classNames from 'classnames'
import Toast from '@/components/Toast'
import { useDepositFunds } from '@/features/wallet/modals/deposit/DepositFundsProvider'
import { DEPOSIT_CLOUDINARY_PATH } from '@/features/walletLegacy/core/constants'
import { Button, MultiToggle } from '@/components'
import {
  DepositContextType,
  DepositOptionType,
  DepositOptionValues,
  DepositSequence,
  DepositStepConfiguration,
  DepositStepProps,
  DepositSteps
} from '@/features/wallet/modals/deposit/types'
import {
  paragraphClasses,
  submit
} from '@/components/Modals/ModalV2/shared/props'
import { buildCloudinaryVideoUrl } from '@/util/cloudinaryHelpers'
import TransparentVideo from '@/components/TransparentVideo'
import { ModalHeaderContent } from '@/components/Modals/ModalV2/ModalHeader'
import { cssFormattedSpacing } from '@/util/tailwindHelpers'
import CopyButton from '@/components/Button/CopyButton'
import { HeaderItem } from '@/features/wallet/components/HeaderItem'
import CoinbaseOverlayDisclaimer from '@/features/wallet/components/CoinbaseOverlayDisclaimer'

const ADDRESS_INSTRUCTIONS =
  'Copy the address below and paste it into your crypto exchange or external Solana wallet. Then double-check that your network is set to Solana. No memo required.'

const QRCODE_INSTRUCTIONS =
  'Scan the QR below from inside your crypto exchange’s app or external Solana wallet. Then double-check that your network is set to Solana. No memo required.'

const QRCode = ({ htmlString }) => (
  <div dangerouslySetInnerHTML={{ __html: htmlString }} />
)

type SolanaDepositOptionsProps = {
  depositOptionValues: DepositOptionValues
  selectedOption: DepositOptionType
  onChange: (option: DepositOptionType) => void
}

const SolanaDepositOptions = ({
  depositOptionValues,
  selectedOption,
  onChange
}: SolanaDepositOptionsProps) => {
  return (
    <div>
      <MultiToggle
        className="mb-3 bg-gray-800"
        items={[
          { label: 'Copy Address', value: 'copyAddress' },
          { label: 'Scan QR Code', value: 'scanQRCode' }
        ]}
        value={selectedOption}
        onToggle={val => {
          onChange?.(val as DepositOptionType)
        }}
      />
      <div
        style={{
          '--containerXPadding': cssFormattedSpacing(3),
          '--containerYPadding': cssFormattedSpacing(2)
        }}
        className={'border border-gray-700 rounded-2xl'}
      >
        <div className="grid grid-cols-2 py-[var(--containerYPadding)] border-b border-inherit">
          <HeaderItem category="Token" label="USDC" icon="usdc" />
          <HeaderItem category="Network" label="Solana" icon="solana" />
        </div>
        <div className="py-[var(--containerYPadding)] px-[var(--containerXPadding)]">
          {selectedOption === 'scanQRCode' ? (
            <div className="flex gap-3 items-center justify-between">
              <div className="h-16 w-16 flex-shrink-0 bg-gray-100">
                <QRCode htmlString={depositOptionValues.barcode} />
              </div>
              <p className="text-gray-300 body-xs">
                To easily paste your USDC deposit address, scan this QR code
                from inside your crypto exchange’s app or external USDC wallet.
              </p>
            </div>
          ) : (
            <>
              <div className="flex gap-3 items-center justify-between">
                <div>
                  <h4 className="h7 mb-1 text-gray-300">Solana Address</h4>
                  <p className="break-all body-sm">
                    {depositOptionValues.address}
                  </p>
                </div>
                <CopyButton
                  className="flex-shrink-0"
                  valueToCopy={depositOptionValues.address}
                  successMessage="Address Copied."
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const SolanaDepositStep = ({
  ModalBody,
  ModalFooter,
  sequence
}: DepositStepProps) => {
  const { transitionTo, hasDeposited, deposit, updateDeposit } =
    useDepositFunds()

  const handleNextClick = () =>
    transitionTo({
      step: 'solanaDoneStep',
      sequence
    })

  const handleOptionChange = (selectedOption: DepositOptionType) =>
    updateDeposit({ ...deposit, selectedOption })

  const instructions =
    deposit.selectedOption === 'copyAddress'
      ? ADDRESS_INSTRUCTIONS
      : QRCODE_INSTRUCTIONS

  return (
    <>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <p className={classNames(paragraphClasses)}>{instructions}</p>
          {!hasDeposited && (
            <Toast type="information" inline>
              Pro Tip: Since this is your first time depositing funds at Droppp,
              you may want to consider sending a small amount first.
            </Toast>
          )}
          <SolanaDepositOptions
            depositOptionValues={deposit.depositOptionValues}
            selectedOption={deposit.selectedOption}
            onChange={handleOptionChange}
          />
          <p className="text-alert body-xs">
            Sending USDC to the above address via a non-Solana network or
            sending any other cryptocurrency will result in a loss of funds,
            which Droppp cannot recover.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button {...submit} onClick={handleNextClick}>
          Next
        </Button>
      </ModalFooter>
    </>
  )
}

type MediaType = ModalHeaderContent

const solanaAddressVideo: MediaType = {
  video: {
    path: DEPOSIT_CLOUDINARY_PATH,
    id: 'tutorial-solana-desktop'
  }
}

const solanaQRCodeVideo: MediaType = {
  component: (
    <TransparentVideo
      sources={{
        mp4: {
          src: buildCloudinaryVideoUrl(
            DEPOSIT_CLOUDINARY_PATH,
            'tutorial-solana-mobile-hevc'
          )
        },
        webm: {
          src: buildCloudinaryVideoUrl(
            DEPOSIT_CLOUDINARY_PATH,
            'tutorial-solana-mobile-webm'
          )
        }
      }}
    />
  )
}

SolanaDepositStep.configure = (
  context: DepositContextType,
  sequence: DepositSequence
): DepositStepConfiguration => {
  const { transitionTo, deposit } = context

  let media: MediaType
  let command: string

  if (deposit.selectedOption === 'copyAddress') {
    media = solanaAddressVideo
    command = 'Paste'
  } else {
    media = solanaQRCodeVideo
    command = 'Scan'
  }

  const handleRetreatClick = () =>
    transitionTo({
      step: 'solanaConfirmStep',
      sequence
    })

  return {
    id: DepositSteps.solanaDepositStep,
    title: ` ${command} Address & Confirm Network`,
    overlayHeaderOpaqueOnScroll: true,
    onRetreat: handleRetreatClick,
    header: {
      overlayComponent: <CoinbaseOverlayDisclaimer />,
      className: 'bg-gradient-to-b from-gray-850/0 to-black aspect-[9/5]',
      ...media
    },
    view: props => (
      <SolanaDepositStep sequence={sequence} {...props}></SolanaDepositStep>
    )
  }
}

export { SolanaDepositStep }
