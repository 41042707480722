import { useState } from 'react'
import InfoModal from '@/components/Modals/ModalV2/InfoModal'
import { MODAL_ID } from '@/constants/modalId'
import NiceModal from '@ebay/nice-modal-react'
import { useWallet } from '../providers/wallet'
import { useWalletRecoveryCode } from '@/features/wallet/hooks/recovery'
import { showModal } from '@/components/Modals/ModalV2'
import {
  notifyWalletConnected,
  notifyWalletError
} from '@/features/wallet/utils/notify'

const AccountVerified = NiceModal.create<{}>(() => {
  const { finishWalletRecovery } = useWallet()
  const [isRegistering, setIsRegistering] = useState(false)
  const { credentialsBundle, removeRecoveryCode } = useWalletRecoveryCode()

  const handleCreateClick = async () => {
    try {
      setIsRegistering(true)
      await finishWalletRecovery(credentialsBundle)
      removeRecoveryCode()
      notifyWalletConnected()
    } catch (err: unknown) {
      notifyWalletError(err)
      showModal(MODAL_ID.wallet.reconnect)
    }
    setIsRegistering(false)
  }

  return (
    <InfoModal
      title={
        <>
          Account verified!<br></br>Now, let’s create a passkey
        </>
      }
      header={{
        image: {
          id: 'accountVerified',
          path: 'global/modals/wallet/account-verified/',
          alt: 'monster pulling a lever',
          width: 480,
          height: 300
        }
      }}
      id={MODAL_ID.wallet.accountVerified}
      primaryButton={{
        loading: isRegistering,
        label: 'Create Passkey',
        onClick: handleCreateClick
      }}
    >
      To reconnect your wallet, we need to set up a new passkey.
    </InfoModal>
  )
})

export default AccountVerified
