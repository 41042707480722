import React from 'react'
import {
  IModalSequenceControllerProps,
  IModalSequenceViewProps
} from '../../../ModalSequence'
import { useForm } from 'react-hook-form'
import { ModalButtonProps, ModalChildrenWrappers } from '../../../BaseModal'
import { ModalFooterButtons } from '../../../ModalFooterButtons'
import RoundedBox from '@/components/RoundedBox'
import { useAuth } from '@/contexts/auth'
import Separator from '@/components/Separator'
import { useNavigation } from '@/hooks/useNavigation'
import { Toast } from '@/components'

export const confirmEmail = (
  sequenceProps: IModalSequenceControllerProps
): IModalSequenceViewProps => {
  return {
    id: 'step2',
    title: 'Let’s double check your email address',
    overlayHeaderOpaqueOnScroll: true,
    header: {
      image: {
        path: 'pages/wallet/migration/',
        id: 'check-email',
        alt: 'verify email',
        width: 480,
        height: 300
      }
    },
    view: viewProps => <ConfirmEmailForm {...viewProps} {...sequenceProps} />
  }
}

interface ConfirmEmailFormProps
  extends Omit<ModalChildrenWrappers, 'ModalHeaderMedia'>,
    IModalSequenceControllerProps {}

const ConfirmEmailForm = ({
  ModalBody,
  ModalFooter,
  next
}: ConfirmEmailFormProps) => {
  const { handleSubmit } = useForm()
  const { goTo } = useNavigation()

  const {
    user: { email, oauth_provider }
  } = useAuth()

  const onSubmit = () => {
    next()
  }

  const secondaryButton: ModalButtonProps = oauth_provider
    ? {
        theme: 'secondary',
        label: 'Contact Support',
        onClick: () => window.open('/support/', '_blank')
      }
    : {
        theme: 'secondary',
        label: 'Update Email',
        onClick: () => goTo.account()
      }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody className="space-y-3">
        <p className="text-gray-300">
          Before setting up your Droppp Wallet, please take a moment to review
          and update your Droppp account email address if needed. It’s important
          to ensure your email is accurate, as it will serve as a recovery
          option if you ever need to reconnect your wallet on a different
          device.
        </p>
        <Toast
          description="If you are using a work or school email where you may lose access, we recommend changing to a personal email."
          type="attention"
          inline
        />
        <RoundedBox className="bg-gray-800 space-y-[12px] px-3 py-[20px] rounded-2xl">
          <p className="h8 text-gray-300">Droppp Email Address</p>
          <p>{email}</p>
          {oauth_provider && (
            <>
              <Separator className="border-gray-700" />
              <p className="body-sm text-alert">
                Your Droppp account was created using Sign In with{' '}
                <span className="capitalize">{oauth_provider}</span>. If you
                wish to use a different email, you will need to contact Droppp
                Support.
              </p>
            </>
          )}
        </RoundedBox>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons
          primaryButton={{
            label: 'Confirm Email',
            onClick: onSubmit
          }}
          secondaryButton={secondaryButton}
        />
      </ModalFooter>
    </form>
  )
}
