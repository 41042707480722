import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { MODAL_ID } from '@/constants/modalId'
import ModalSequence from '../../ModalSequence'
import { welcome } from './steps/welcome'
import { confirmEmail } from './steps/confirmEmail'
import { createPasskey } from './steps/createPasskey'
import { backupSeedPhrase } from './steps/backupSeedPhrase'
import { seedPhrase } from './steps/seedPhrase'

interface Props {
  onComplete?: () => void
}

export default NiceModal.create(({ onComplete }: Props) => (
  <ModalSequence
    id={MODAL_ID.wallet.create.sequence}
    modalId={MODAL_ID.wallet.create.sequence}
    init={sequenceProps => [
      welcome(sequenceProps),
      confirmEmail(sequenceProps),
      createPasskey(sequenceProps),
      backupSeedPhrase(sequenceProps),
      seedPhrase(sequenceProps, onComplete)
    ]}
  />
))
