import {
  AddressPayload,
  AmountPayload,
  Withdrawal
} from '@/features/wallet/modals/withdraw/types'

export type SetAddressAction = {
  type: 'SET_ADDRESS'
  payload: AddressPayload
}

export type SetAmountAction = { type: 'SET_AMOUNT'; payload: AmountPayload }

export type WithdrawalAction = SetAddressAction | SetAmountAction

export const defaultWithdrawal = (): Withdrawal => ({})

export const withdrawalReducer = (
  state: Withdrawal,
  action: WithdrawalAction
): Withdrawal => {
  switch (action.type) {
    case 'SET_ADDRESS': {
      const { address } = action.payload
      return { ...state, address }
    }
    case 'SET_AMOUNT': {
      const { amount } = action.payload
      return {
        ...state,
        amount
      }
    }
    default: {
      throw new Error('Invalid action type')
    }
  }
}
