import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { showModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'
import { useAuth } from '@/contexts/auth'

import { WalletColumnHeader } from '@/features/wallet/components/WalletColumnHeader'
import ColumnCustomAddressUpsell from '@/features/wallet/components/ColumnCustomAddressUpsell'
import { WalletAddress } from '@/features/wallet/components/WalletAddress'
import { CARD_TYPES } from '@/enum'
import { getUsersWaxAddressInfo } from '@/util/accountHelpers'

enum Title {
  Address = 'Address'
}

const Content = {
  AddressDescription:
    'Your Droppp Address is a WAX blockchain-based address that serves as your profile name and is used to hold and transfer digital collectibles.'
}

export const AddressPanel = () => {
  const router = useRouter()
  const { user = {}, wallets } = useAuth()

  useEffect(() => {
    if (!user) return

    const { addressPurchased } = router.query
    const { account_wax_free, account_wax } = user
    const alreadyPurchased = account_wax !== account_wax_free

    if (addressPurchased && alreadyPurchased) {
      showModal(MODAL_ID.wallet.waxPurchaseSuccess, {
        onComplete: () => {
          delete router.query.addressPurchased
          router.push(router).then()
        }
      })
    }
  }, [router.query, user])

  if (!user) return

  const { addressType } = getUsersWaxAddressInfo(user)
  const hasCustomWaxAddressUpsell = addressType !== 'custom'

  // get wallet, favoring custom
  const sortedWallets = wallets.sort(a =>
    a.type === CARD_TYPES.CUSTOM ? -1 : 1
  )

  return (
    <>
      <WalletColumnHeader
        title={Title.Address}
        description={Content.AddressDescription}
      />
      {hasCustomWaxAddressUpsell && (
        <ColumnCustomAddressUpsell addressType={addressType} />
      )}
      {!!sortedWallets.length &&
        sortedWallets.map((wallet, index) => (
          <WalletAddress
            key={wallet.address}
            wallet={wallet}
            avatar={!index ? user.avatar_media : null}
          />
        ))}
    </>
  )
}
