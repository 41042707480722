import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

export const useWalletRecoveryCode = () => {
  const credentialsRef = useRef<string>()
  const router = useRouter()

  const removeRecoveryCode = () => {
    const { pathname, query } = router
    delete router.query.dwcode
    delete router.query.modalId
    router.replace({ pathname, query }, undefined, { shallow: true }).then()
  }

  useEffect(() => {
    if (router.query.dwcode) {
      const { dwcode } = router.query
      const credentials = dwcode?.toString()

      if (credentials) {
        credentialsRef.current = credentials
      }
    }
  }, [router.query.dwcode])

  return { credentialsBundle: credentialsRef.current, removeRecoveryCode }
}
