import { postJSON } from '@/api/core/http/post'

import {
  Web3WithdrawalInput,
  Web3WithdrawalResponse,
  web3WithdrawalResponseSchema
} from './schema'

const path = '/user/web3/withdrawal/'

export const submitWeb3Withdrawal = async (
  input: Web3WithdrawalInput
): Promise<Web3WithdrawalResponse> =>
  postJSON<Web3WithdrawalResponse>(
    path,
    input,
    {},
    web3WithdrawalResponseSchema
  )
