import DefaultModal, { hideModal } from '@/components/Modals/ModalV2'
import React from 'react'
import { RECOVERY_EMAIL_MODAL_ID } from './constants'
import { Button, Input, Toast } from '@/components'
import { useForm } from 'react-hook-form'
import Field from '@/components/Field'
import {
  EmailValidationPattern,
  shouldMatchValue
} from '@/util/forms/inputValidators'
import { useWallet } from '@/features/wallet/providers/wallet'
import { notifyWalletError } from '@/features/wallet/utils/notify'
import * as modalProps from '@/components/Modals/ModalV2/shared/props'

const INPUTS = {
  updatedEmail: 'updatedEmail',
  confirmedEmail: 'confirmedEmail'
}

export const WalletRecoveryEmailModal = () => {
  return (
    <DefaultModal
      id={RECOVERY_EMAIL_MODAL_ID}
      title="Change Wallet Recovery Email"
      onRetreat={() => hideModal(RECOVERY_EMAIL_MODAL_ID)}
    >
      {modalProps => <ChangeRecoveryEmailForm {...modalProps} />}
    </DefaultModal>
  )
}

export const notifyEmailUpdated = () => {
  Toast({
    type: 'success',
    autoClose: 5000,
    description: 'Wallet recovery email updated.'
  })
}

const ChangeRecoveryEmailForm = ({ ModalBody, ModalFooter }) => {
  const { updateRecoveryEmail } = useWallet()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid, isSubmitting }
  } = useForm({
    mode: 'onChange',
    delayError: 1000
  })

  const handleSubmitForm = handleSubmit(async formData => {
    const { updatedEmail } = formData

    try {
      await updateRecoveryEmail(updatedEmail)
      hideModal(RECOVERY_EMAIL_MODAL_ID)
      notifyEmailUpdated()
    } catch (err) {
      notifyWalletError(err)
    }
  })

  // refactor patterns for required and email regex

  return (
    <form onSubmit={handleSubmitForm}>
      <ModalBody>
        <div className="space-y-3">
          <p className="text-gray-300">
            Update the email address used to reconnect your wallet if your
            passkeys are unavailable.
          </p>
          <Toast
            description="We recommend using a personal email instead of a work or school email, which you may lose access to later."
            type="attention"
            inline
          />
          <Field
            className="w-full"
            name={INPUTS.updatedEmail}
            errors={errors}
            label="New Recovery Email"
          >
            <Input
              register={register(INPUTS.updatedEmail, {
                required: 'Email is required.',
                pattern: EmailValidationPattern
              })}
              id={INPUTS.updatedEmail}
              type="email"
              placeholder="Enter Email"
              hasError={errors[INPUTS.updatedEmail]}
            />
          </Field>
          <Field
            className="w-full"
            name={INPUTS.confirmedEmail}
            errors={errors}
            label="New Recovery Email"
          >
            <Input
              type="email"
              register={register(INPUTS.confirmedEmail, {
                required: 'Confirm email is required.',
                pattern: EmailValidationPattern,
                validate: {
                  shouldMatch: val => {
                    const email = getValues(INPUTS.updatedEmail)
                    return shouldMatchValue(val, email, 'Emails do not match')
                  }
                }
              })}
              label="Confirm Email"
              id={INPUTS.confirmedEmail}
              placeholder="Enter email again"
            />
          </Field>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!isValid}
          loading={isSubmitting}
          {...modalProps.submit}
        >
          APPLY
        </Button>
      </ModalFooter>
    </form>
  )
}
