import { ReactNode } from 'react'
import { classNames } from '@/util/tailwindHelpers'
import Icon, { Icons } from '@/components/Icon'
import { tv } from 'tailwind-variants'

export interface IStatusBadgeProps {
  status?: 'alert' | 'error' | 'success' | 'default' | 'completed'
  children: ReactNode
  icon?: Icons
  size?: 'sm' | 'default' // TODO:? | 'lg' | 'xl'
  iconSize?: number
  iconClassName?: string
  className?: string
}
export const StatusBadge = ({
  className,
  children,
  icon,
  iconSize,
  iconClassName,
  size = 'default',
  status
}: IStatusBadgeProps) => {
  const showIcon = icon && (
    <Icon name={icon} size={iconSize} className={iconClassName} />
  )

  return (
    <div
      className={classNames(
        badgeClasses({ theme: status, size }),
        showIcon && 'pl-[5px]',
        className
      )}
    >
      {showIcon}
      {children}
    </div>
  )
}

const badgeClasses = tv({
  base: 'flex items-center border-1 rounded-full whitespace-nowrap font-bold uppercase px-1',
  variants: {
    theme: {
      alert: 'text-alert border-alert',
      error: 'text-error border-error',
      success: 'text-success border-success',
      default: 'text-gray-400 border-gray-400',
      completed: 'text-white border-blue'
    },
    size: {
      sm: 'py-[1px] text-xxs',
      default: 'py-[3px] text-xxs'
    }
  }
})
