import React from 'react'
import { IStatusBadgeProps, StatusBadge } from '../StatusBadge'
import { TransactionChainStatus } from '@/api/resources/shared/action'

type Props = {
  status: TransactionChainStatus
  className?: string
}

export default function ActivityStatusBadge({ status, className }: Props) {
  let badgeStatus: IStatusBadgeProps['status'] = 'default'

  if (status === 'completed') {
    badgeStatus = 'completed'
  } else if (status === 'failed') {
    badgeStatus = 'error'
  }

  return (
    <StatusBadge className={className} status={badgeStatus}>
      {status}
    </StatusBadge>
  )
}
