import React from 'react'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { Button } from '@/components'
import {
  DepositContextType,
  DepositSequence,
  DepositStepConfiguration,
  DepositStepProps,
  DepositSteps
} from '@/features/wallet/modals/deposit/types'
import { useDepositFunds } from '@/features/wallet/modals/deposit/DepositFundsProvider'
import { ControlledConfirmation } from '@/components/AcknowledgeBox/Confirmation'
import { DEPOSIT_CLOUDINARY_PATH } from '@/features/walletLegacy/core/constants'
import {
  paragraphClasses,
  submit
} from '@/components/Modals/ModalV2/shared/props'
import CoinbaseOverlayDisclaimer from '@/features/wallet/components/CoinbaseOverlayDisclaimer'
import { cssFormattedSpacing } from '@/util/tailwindHelpers'
import { HeaderItem } from '@/features/wallet/components/HeaderItem'
import { TransferWarning } from '@/features/wallet/components/TransferWarning'
import InlineNetworkIcon from '@/features/wallet/components/InlineNetworkIcon'

type ConfirmUsdcForm = {
  confirmedUsdc: boolean
}

const ConfirmUsdcStep = ({
  ModalBody,
  ModalFooter,
  sequence
}: DepositStepProps) => {
  const { transitionTo, updateDeposit, deposit } = useDepositFunds()

  const { confirmedUsdc } = deposit

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<ConfirmUsdcForm>({ defaultValues: { confirmedUsdc } })

  const submitForm = handleSubmit(formData => {
    updateDeposit(formData)
    transitionTo({
      step: 'solanaConfirmStep',
      sequence
    })
  })

  return (
    <form onSubmit={submitForm}>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <p className={classNames(paragraphClasses)}>
            Be sure you are only sending USDC from your crypto exchange or an
            external Solana wallet.
          </p>

          <div
            style={{
              '--containerXPadding': cssFormattedSpacing(3),
              '--containerYPadding': cssFormattedSpacing(2)
            }}
            className={'border border-gray-700 rounded-2xl'}
          >
            <div className="grid grid-cols-2 py-[var(--containerYPadding)] border-b border-inherit">
              <HeaderItem category="Token" label="USDC" icon="usdc" />
            </div>

            <div className="py-[var(--containerYPadding)] px-[var(--containerXPadding)]">
              <TransferWarning />
            </div>
          </div>
          <ControlledConfirmation
            className="bg-gray-800"
            name="confirmedUsdc"
            control={control}
            required
          >
            Yes, I’ve confirmed that the token I am sending is{' '}
            <InlineNetworkIcon title="USDC" icon="usdc" />.
          </ControlledConfirmation>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={!isValid} {...submit} type="submit">
          Next
        </Button>
      </ModalFooter>
    </form>
  )
}

ConfirmUsdcStep.configure = (
  context: DepositContextType,
  sequence: DepositSequence
): DepositStepConfiguration => {
  const { transitionTo, deposit } = context

  const handleRetreatClick = () => {
    const step = deposit.hasUsdc ? 'addUsdcStep' : 'howToUsdcStep'
    transitionTo({
      step,
      sequence
    })
  }

  return {
    id: DepositSteps.confirmUsdcStep,
    overlayHeaderOpaqueOnScroll: true,
    title: "Verify You're Sending USDC",
    onRetreat: handleRetreatClick,
    header: {
      overlayComponent: <CoinbaseOverlayDisclaimer />,
      className: 'bg-gradient-to-b from-gray-850/0 to-black aspect-[9/5]',
      video: {
        path: DEPOSIT_CLOUDINARY_PATH,
        id: 'tutorial-solana-usdc'
      }
    },
    view: props => (
      <ConfirmUsdcStep sequence={sequence} {...props}></ConfirmUsdcStep>
    )
  }
}

export { ConfirmUsdcStep }
