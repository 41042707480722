import { z } from 'zod'
import {
  listingBalanceSchema,
  listingPurchaseSchema
} from '@/api/resources/user/listing/purchase/shared'

export const web3PurchaseStartSchema = z.object({
  reservation_id: z.string(),
  buyer_address: z.string(),
  seller_address: z.string(),
  buyer_token_address: z.string(),
  seller_token_address: z.string(),
  balance: listingBalanceSchema,
  listing: listingPurchaseSchema,
  solana_priority_rate: z.number()
})

export const web3PurchaseStartResponseSchema = web3PurchaseStartSchema

export type Web3PurchaseStart = z.infer<typeof web3PurchaseStartSchema>

export type Web3PurchaseStartResponse = z.infer<
  typeof web3PurchaseStartResponseSchema
>
