import { AxiosRequestConfig } from 'axios'

import {
  RequestMeta,
  ServerContext,
  ServerRequestMeta
} from '@/api/core/http/types'

export interface RequestConfig extends AxiosRequestConfig {
  _isRetry?: boolean
  meta?: ServerRequestMeta | RequestMeta
  queryKey?: unknown[]
}

export const getContextFromRequest = (
  request: RequestConfig
): ServerContext => {
  const serverRequestMeta = request?.meta as ServerRequestMeta
  return serverRequestMeta?.serverContext
}
