import post from '@/api/core/http/post'
import { buildApiUrl } from '@/api/core/url'
import {
  Web3PurchaseStartResponse,
  web3PurchaseStartResponseSchema
} from './schema'

const path = `/user/listing/purchase/start`

export const purchaseListingStart = async (
  reservation_id: string
): Promise<Web3PurchaseStartResponse> =>
  post<Web3PurchaseStartResponse>(
    buildApiUrl(path, { reservation_id }),
    {},
    web3PurchaseStartResponseSchema
  )
