import classNames from 'classnames'
import React from 'react'
import {
  DepositContextType,
  DepositSequence,
  DepositStepConfiguration,
  DepositStepProps,
  DepositSteps
} from '@/features/wallet/modals/deposit/types'
import {
  footer,
  paragraphClasses,
  submit
} from '@/components/Modals/ModalV2/shared/props'
import { Button } from '@/components'
import { hideModal } from '@/components/Modals/ModalV2'
import { MODAL_ID } from '@/constants/modalId'

const SolanaDoneStep = ({ ModalBody, ModalFooter }: DepositStepProps) => {
  const handleDoneClick = () => hideModal(MODAL_ID.web3.deposit)

  return (
    <>
      <ModalBody>
        <p className={classNames(paragraphClasses)}>
          To finish depositing funds into your Droppp Balance please review and
          complete your transaction in your crypto exchange or external wallet.
          Then sit back and relax; funds typically arrive within 10 minutes.
        </p>
      </ModalBody>
      <ModalFooter {...footer}>
        <Button {...submit} onClick={handleDoneClick}>
          Done
        </Button>
      </ModalFooter>
    </>
  )
}

SolanaDoneStep.configure = (
  context: DepositContextType,
  sequence: DepositSequence
): DepositStepConfiguration => {
  const { transitionTo } = context

  const handleRetreatClick = () =>
    transitionTo({
      step: 'solanaDepositStep',
      sequence
    })

  return {
    id: DepositSteps.solanaDoneStep,
    title: 'Almost Done',
    overlayHeaderOpaqueOnScroll: true,
    onRetreat: handleRetreatClick,
    header: {
      image: {
        path: 'global/modals/',
        id: 'what-is-usdc',
        alt: 'header image',
        width: 480,
        height: 300,
        className: 'aspect-[24/15]'
      }
    },
    view: props => (
      <SolanaDoneStep sequence={sequence} {...props}></SolanaDoneStep>
    )
  }
}

export { SolanaDoneStep }
