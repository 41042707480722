import React from 'react'
import { Icon } from '@/components'
import { classNames } from '@/util/tailwindHelpers'

type Props = {
  connected: boolean
  className?: string
}

export default function ConnectedStatusIcon({ connected, className }: Props) {
  const name = connected ? 'tickCircle' : 'cancel'
  return (
    <Icon
      name={name}
      className={classNames('overflow-visible w-[14px]', className)}
    />
  )
}
