import { postJSON } from '@/api/core/http/post'

import {
  SolanaAccountExistsInput,
  SolanaAccountExistsResponse,
  solanaAccountExistsResponseSchema
} from './schema'

const path = '/solana/exists/'

export const checkSolanaAccountExists = async (
  input: SolanaAccountExistsInput
): Promise<SolanaAccountExistsResponse> =>
  postJSON<SolanaAccountExistsResponse>(
    path,
    input,
    {},
    solanaAccountExistsResponseSchema
  )
