import { Icon } from '@/components'
import { Icons } from '@/components/Icon'

interface Props {
  title: string
  icon: Icons
}

const InlineNetworkIcon = ({ title, icon }: Props) => (
  <span className="whitespace-nowrap">
    <Icon name={icon} className="inline h-2 mt-[-2px]" />
    &nbsp;{title}
  </span>
)

export default InlineNetworkIcon
