import LineItem from './LineItem'
import Image from 'next/image'

export type TransactionParty = {
  address: string
  avatarUrl?: string
}

const TransactionParty = ({ avatarUrl, address_display, hideAvatar }) => {
  const shouldShowAvatar = !hideAvatar && avatarUrl
  return (
    <div className="flex gap-1 items-center justify-end">
      {shouldShowAvatar && (
        <Image
          className="rounded-[6px]"
          src={avatarUrl}
          alt="avatar"
          width={18}
          height={18}
        />
      )}

      <div>{address_display}</div>
    </div>
  )
}

const PartyLineItems = ({
  parties: { from, to },
  hideAvatar
}: {
  parties: { from: TransactionParty; to: TransactionParty }
  hideAvatar?: boolean
}) => {
  return (
    <>
      <LineItem
        property="From"
        value={
          <TransactionParty
            hideAvatar={hideAvatar}
            avatarUrl={from.avatarUrl}
            address_display={from.address}
          />
        }
      />
      <LineItem
        property="To"
        value={
          <TransactionParty
            hideAvatar={hideAvatar}
            avatarUrl={to.avatarUrl}
            address_display={to.address}
          />
        }
      />
    </>
  )
}

export default PartyLineItems
