import { useWallet } from '../providers/wallet'
import IdentityVerificationState from '@/types/identityVerificationState'
import { useMemo } from 'react'
import { UserStatus } from '../types'
import { Content } from '../components/CreateWalletSection/components/Content'
import { useRouter } from 'next/router'

export const useWalletUserStatus = () => {
  const router = useRouter()
  const { balance } = useWallet()

  const { identityVerification, migrationNeeded, migrationStep } = useWallet()

  const userIsKYCVerified =
    identityVerification === IdentityVerificationState.Completed

  const userStatus = useMemo((): UserStatus => {
    if (!userIsKYCVerified) return 'unverified'
    if (migrationNeeded && migrationStep) return 'startedMigration'
    if (migrationNeeded) return 'canMigrate'
    return 'verifiedNoWallet'
  }, [userIsKYCVerified, migrationNeeded, migrationStep])

  return {
    status: userStatus,
    content: useMemo(
      () => Content(router, balance)[userStatus],
      [userStatus, router]
    ),
    userIsKYCVerified: userIsKYCVerified
  }
}
