import { z } from 'zod'
import { successResponseSchema } from '@/api/resources/shared/success'

export const web3RecoverInputSchema = z.object({
  publicKey: z.string()
})

export const web3RecoverResponseSchema = successResponseSchema

export type Web3RecoverInput = z.infer<typeof web3RecoverInputSchema>
export type Web3RecoverResponse = z.infer<typeof web3RecoverResponseSchema>
