import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import { MODAL_ID } from '@/constants/modalId'
import { CLOUDINARY_WALLET_PATH } from '@/pages/wallet'
import Button from '@/components/Button'
import CloudinaryImage from '@/components/CloudinaryImage'
import RoundedBox from '@/components/RoundedBox'
import { DropppAddress } from '@/util/accountHelpers'
import Link from 'next/link'

interface Props {
  addressType: DropppAddress['addressType']
}

export default function ColumnCustomAddressUpsell({ addressType }: Props) {
  const isAddressTypeNone = addressType === 'none'

  return (
    <aside>
      <RoundedBox className="!bg-gray-800 flex flex-col text-center lg:text-left lg:flex-row items-center gap-3 !p-3">
        <div className="max-lg:order-1">
          <h4 className="h5 mb-1">
            {isAddressTypeNone
              ? 'Set up Your Droppp Address'
              : 'Make your profile sing!'}
          </h4>

          {isAddressTypeNone ? (
            <p className="body-sm mb-2 text-gray-300">
              You'll receive a free random Droppp Address by making your first
              purchase during a drop or setting up your{' '}
              <Link href="/wallet" className="inline-link">
                Droppp Wallet
              </Link>
              , alternatively you can setup your Droppp Address now by creating
              a custom address for $4.99.
            </p>
          ) : (
            <p className="body-sm mb-2 text-gray-300">
              Stand out with a unique and easy to remember Droppp address for a
              one-time purchase of $4.99.
            </p>
          )}
          <Button
            className="max-lg:w-full"
            size="xs"
            onClick={() =>
              NiceModal.show(MODAL_ID.wallet.customWaxAddress, {
                presentingContext: 'wallet',
                isAddressTypeNone: addressType == 'none'
              })
            }
          >
            Get Custom Address
          </Button>
        </div>
        <div className="aspect-1 relative min-w-[139px]">
          <CloudinaryImage
            layout="fill"
            imageId={'upgrade-address'}
            path={CLOUDINARY_WALLET_PATH}
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      </RoundedBox>
    </aside>
  )
}
