import { z } from 'zod'
import { ApiUrl } from '../url'
import { originHttp, remoteHttp } from './axios'
import { parseForSchema } from '@/util/jsonHelper'

export const postJSON = async <T>(
  path: string,
  data?: {},
  options?: {},
  schema?: z.ZodType<T>
): Promise<T> => {
  const response = await originHttp.post(path, data, options)
  return parseForSchema(response.data, schema)
}

const post = async <T>(
  { path, params }: ApiUrl,
  options?: {},
  schema?: z.ZodType<T>
): Promise<T> => {
  const response = await remoteHttp.post(path, params, options)
  return parseForSchema(response.data, schema)
}

export default post
