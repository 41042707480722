import { Button } from '@/components'
import GradientText from '@/components/GradientText'
import PoweredBySolana from '@/components/PoweredBySolana'

import { useWalletUserStatus } from '../../hooks/status'

const CreateWalletSection = () => {
  const { content, userIsKYCVerified } = useWalletUserStatus()

  return (
    <>
      <div className="mb-2">
        <GradientText text={content.title} className="h3" theme={'solana'} />
        <PoweredBySolana iconClassName="w-8 inline-block ml-[2px]" />
      </div>

      <p className="text-gray-300 mb-2">{content.description}</p>

      <div className="mb-3">{content.bodyComponent}</div>

      <div className="space-y-3">
        <Button
          className="w-full"
          disabled={!userIsKYCVerified}
          theme="solana"
          onClick={content.primaryButton.action}
        >
          {content.primaryButton.label}
        </Button>

        {content.secondaryButton && (
          <Button
            theme="clean-blue"
            className="w-full"
            onClick={content.secondaryButton.action}
          >
            {content.secondaryButton.label}
          </Button>
        )}
      </div>
    </>
  )
}

export default CreateWalletSection
