import classNames from 'classnames'
import { Button } from '@/components'
import { useDepositFunds } from '@/features/wallet/modals/deposit/DepositFundsProvider'
import {
  DepositSequence,
  DepositStepConfiguration,
  DepositStepProps,
  DepositSteps
} from '@/features/wallet/modals/deposit/types'
import {
  submit,
  paragraphClasses,
  footer
} from '@/components/Modals/ModalV2/shared/props'

const AddUsdcStep = ({
  ModalBody,
  ModalFooter,
  sequence
}: DepositStepProps) => {
  const { transitionTo, updateDeposit } = useDepositFunds()

  const handleNeedsUsdcClick = () => {
    updateDeposit({ hasUsdc: false })
    transitionTo({
      step: 'howToUsdcStep',
      sequence
    })
  }

  const handleHasUsdcClick = () => {
    updateDeposit({ hasUsdc: true })
    transitionTo({
      step: 'confirmUsdcStep',
      sequence
    })
  }

  return (
    <>
      <ModalBody>
        <p className={classNames(paragraphClasses)}>
          Droppp Marketplace only accepts USDC for funding your account. USDC is
          a stablecoin that is meant to work like a substitute for a U.S.
          dollar. If you don’t have USDC, we will help you purchase some.
          Otherwise, you may proceed with funding your account.
        </p>
      </ModalBody>
      <ModalFooter {...footer}>
        <Button {...submit} theme="white" onClick={handleNeedsUsdcClick}>
          How to get&nbsp;USDC
        </Button>
        <Button {...submit} theme="blue" onClick={handleHasUsdcClick}>
          I&nbsp;have USDC
        </Button>
      </ModalFooter>
    </>
  )
}

AddUsdcStep.configure = (
  sequence: DepositSequence
): DepositStepConfiguration => {
  return {
    id: DepositSteps.addUsdcStep,
    title: 'Add USDC Funds',
    overlayHeaderOpaqueOnScroll: true,
    header: {
      image: {
        path: 'global/modals/',
        id: 'intro-to-wallet',
        alt: 'Add USDC Funds',
        width: 480,
        height: 300,
        className: 'aspect-[24/15]'
      }
    },
    view: props => <AddUsdcStep sequence={sequence} {...props}></AddUsdcStep>
  }
}

export { AddUsdcStep }
