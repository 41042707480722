import { z } from 'zod'

export const registeredWeb3WalletSchema = z.object({
  address: z.string(),
  tk_suborg_id: z.string().uuid(),
  tk_user_id: z.string().uuid(),
  tk_wallet_id: z.string().uuid()
})

export type RegisteredWeb3Wallet = z.infer<typeof registeredWeb3WalletSchema>

export const web3WalletSchema = z.object({
  tk_email: z.string().nullable(),
  address: z.string(),
  display_address: z.string(),
  associated_address: z.string().nullable(),
  tk_suborg_id: z.string().uuid(),
  tk_user_id: z.string().uuid(),
  tk_wallet_id: z.string().uuid(),
  time_created: z.string(),
  time_updated: z.string(),
  active: z.number()
})

export type Web3Wallet = z.infer<typeof web3WalletSchema>
