import { isBrowser } from '@/util/envHelpers'
import { RequestConfig } from '@/api/core/http/request'
import { path as catalogItemGetPath } from '@/api/resources/catalog/item/get'
import { path as userGetPath } from '@/api/resources/user/get'
import { path as userWeb3GetPath } from '@/api/resources/user/web3/get'
import { path as userWalletGetPath } from '@/api/resources/user/wallet/get'

// We've received approval for `catalog/item/get`, as we confirm other endpoints they can be added here - Eric, Tue Mar 7 2023
const authorizedServerPaths = () => [
  catalogItemGetPath,
  userGetPath,
  userWeb3GetPath,
  userWalletGetPath
]

export const shouldIncludeAccessToken = (request: RequestConfig) =>
  isBrowser || authorizedServerPaths().includes(request.url ?? '')
