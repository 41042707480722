import classNames from 'classnames'
import { NO_LISTING_MESSAGE_MAP } from '@/enum'
import ProductPrice from '@/components/ProductPrice'
import { ListedStateMessage } from './ListedStateMessage'
import { BinaryEnum } from '@/util/zodHelpers'

const SIZE_MAP = {
  default: {
    fontSize: '2em',
    lineHeight: '1.4em'
  },
  small: {
    fontSize: '1.5em',
    lineHeight: '1.2em'
  },
  tiny: {
    fontSize: '1em',
    lineHeight: '1em'
  }
}

interface Props {
  className?: string
  size?: 'tiny' | 'small' | 'default'
  isCatalogAsset?: boolean
  listing_price: number | null
  isUniqueProduct: boolean
  noListingType?: number
  hideUpSuffix?: boolean
  migrating?: BinaryEnum
}

const CatalogItemListingInfo = ({
  className,
  size = 'default',
  isCatalogAsset = false,
  listing_price,
  isUniqueProduct,
  noListingType,
  hideUpSuffix,
  migrating
}: Props) => {
  const getContent = () => {
    if (listing_price == null) {
      const message = NO_LISTING_MESSAGE_MAP[noListingType]
      return <span className="text-gray-300">{message}</span>
    }
    const messageStateProps = { isCatalogAsset, migrating }
    const hideSuffix = hideUpSuffix || isUniqueProduct || !isCatalogAsset

    return (
      <>
        <ListedStateMessage {...messageStateProps} />
        <ProductPrice
          price={listing_price}
          className={!isCatalogAsset ? 'text-white' : ''}
          hideAndUpSuffix={hideSuffix}
        />
      </>
    )
  }

  return (
    <div
      style={{
        ...SIZE_MAP[size]
      }}
      className={classNames('pricing-lg', className)}
    >
      {getContent()}
    </div>
  )
}

export default CatalogItemListingInfo
