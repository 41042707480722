import React from 'react'
import { Button } from '@/components'
import { useWithdrawFunds } from '@/features/wallet/modals/withdraw/WithdrawFundsProvider'
import { HeaderItem } from '@/features/wallet/components/HeaderItem'
import { cssFormattedSpacing } from '@/util/tailwindHelpers'
import {
  WithdrawalSequence,
  WithdrawalStepConfiguration,
  WithdrawalStepProps,
  WithdrawalSteps
} from '@/features/wallet/modals/withdraw/types'

const CryptoExchangeStep = ({
  ModalBody,
  ModalFooter,
  sequence
}: WithdrawalStepProps) => {
  const { transitionTo } = useWithdrawFunds()

  const handleNextClick = () => {
    transitionTo({
      step: 'enterAddressStep',
      sequence
    })
  }

  return (
    <>
      <ModalBody>
        <div className="flex flex-col gap-3">
          <div>
            <p className="text-gray-300">
              From your crypto exchange or external Solana wallet, press
              "Receive", select the Solana network, and choose USDC.
            </p>
          </div>
          <div
            style={{
              '--containerXPadding': cssFormattedSpacing(3),
              '--containerYPadding': cssFormattedSpacing(2)
            }}
            className="border border-gray-700 rounded-2xl"
          >
            <div className="grid grid-cols-2 py-[var(--containerYPadding)] border-inherit">
              <HeaderItem category="Token" label="USDC" icon="usdc" />
              <HeaderItem category="Network" label="Solana" icon="solana" />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleNextClick} className="w-full">
          Next
        </Button>
      </ModalFooter>
    </>
  )
}

CryptoExchangeStep.configure = (
  sequence: WithdrawalSequence
): WithdrawalStepConfiguration => {
  return {
    id: WithdrawalSteps.cryptoExchangeStep,
    title: 'Withdraw USDC',
    view: props => (
      <CryptoExchangeStep sequence={sequence} {...props}></CryptoExchangeStep>
    )
  }
}

export { CryptoExchangeStep }
